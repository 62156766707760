import React, {useEffect, useState} from 'react';
import {Modal, Segment, Button, Dimmer, Loader} from 'semantic-ui-react';
import {Box} from 'theme-ui';
import {Ticket} from '../boot-model-forms/ticket';
import {CrazyCustomTicket} from '../boot-model-forms/crazy-custom-ticket';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { type } from '@testing-library/user-event/dist/type';

export const OrderModal = ({
                               lineItem,
                               orderNumber,
                               lineItemPosition,
                               reprintBy,
                               credential,
                           }) => {
    const [open, setOpen] = useState(false);
    const [specFormOpen, setSpecFormOpen] = useState(false);
    const [customReprintButton, setCustomReprintButton] = useState(false);///////dimmer
    const [infoForForm, setInfoForForm] = useState();
    const [rebuildForm, setRebuildOpen] = useState(false);
    const [showInputs, setShowInputs] = useState(true);
    const [orderType, setOrderType] = useState('none');


    


    
 
 


    //reprint custom
    const sendToBuildTicketTwoPointZero = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);
        setSpecFormOpen(false);
        const string = '';

        let proxyObj = {
           // url: process.env.REACT_APP_Crazy_Custom_Link,
           url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            //api_key_lookup_value: process.env.REACT_APP_Crazy_Custom_API_Key,
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                //reprintBy: reprintBy + '',
                //reprintEvenIfFulfilled: true,
                //type: "tryon2",
                //extraOrderId: "5070831943878",
                //extraOrderLineItemId: "12308518863046"
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setCustomReprintButton(false);
            toast.success('Sent to the printer!', {
                position: 'top-center',
                autoClose: 5000.0,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            toast.error('Error sending to the ticket builder. Please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
        setSpecFormOpen(false);
    };
    //reprint custom
    const sendToBuildTicket = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);
        setSpecFormOpen(false);
        const string = '';

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                reprintBy: reprintBy + '',
                reprintEvenIfFulfilled: true,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setCustomReprintButton(false);
            toast.success('Sent to the printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            toast.error('Error sending to the ticket builder. Please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
        setSpecFormOpen(false);
    };
    //reprint custom with changes
    const getBuildTicketSpecs = async (orderNumber, lineItemId) => {
        
        setCustomReprintButton(true);

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                webAppReprint: true,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );

        let response = await buildTicketResponse.json();
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setCustomReprintButton(false);
            setInfoForForm(response);
            setOrderType(response.type);
            setSpecFormOpen(true);
            console.log("GetBuildTicketSpecs Response: ",response);
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };
    //reprint rebuild
    const createRebuild = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);
        setSpecFormOpen(false);
        const string = '';

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                reprintBy: reprintBy + '',
                reprintEvenIfFulfilled: true,
                type: "Rebuild",
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setCustomReprintButton(false);
            toast.success('Sent to the printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            toast.error('Error sending to the ticket builder. Please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
        setSpecFormOpen(false);
        /*
        setCustomReprintButton(true);

        let proxyObj = {
            url: process.env.REACT_APP_Rebuild_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Rebuild_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                reprintBy: reprintBy
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setRebuildOpen(true);
            setCustomReprintButton(false);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            addTag(orderNumber);
        } else {
            setCustomReprintButton(false);
            toast.error('Please print a manual rebuild ticket by pressing the green "Print New Ticket" button.' + buildTicketResponse.status, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
        */
    };
    //reprint rebuild with changes
    const getRebuildTicketSpecs = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);

        let proxyObj = {
            url: process.env.REACT_APP_Rebuild_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Rebuild_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                webAppReprint: true,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );


        let response = await buildTicketResponse.json();


        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            console.log(response);
            setCustomReprintButton(false);
            setInfoForForm(response);
            setOrderType(response.type);
            console.log(response);
            console.log("response above");
            setSpecFormOpen(true);
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };
    //create a ticket from rebuild and override orderType 
    //type name either the typeName you want or none for the given typeName
    //rebuildPrinterYN = true if this is for the rebuild printer
    //changesYN = true if we want the changes in the form
    const createTicketOverride = async (orderNumber, lineItemId, typeName, rebuildPrinterYN, changesYN) => {
        setCustomReprintButton(true);

        //get proxyObj set up
        let proxyObj ;
        if(rebuildPrinterYN)
        {
            console.log('OrderNumber :',orderNumber);
            proxyObj = {
                url: process.env.REACT_APP_Rebuild_Ticket_Link,
                method: 'POST',
                api_key_header: "X-Api-Key",
                api_key_lookup_value: process.env.REACT_APP_Rebuild_Ticket_API_Key,
                headers: [
                    {
                        key: 'Content-Type',
                        value: 'application/json',
                    },
                    {
                        key: 'Access-Control-Allow-Methods',
                        value: '*',
                    },
                ],
                body: {
                    orderNumber: orderNumber + '',
                    lineItemId: lineItemId + '',
                    webAppReprint: true,
                },
            };
        }
        else
        {
            proxyObj = {
                url: process.env.REACT_APP_Build_Ticket_Link,
                method: 'POST',
                api_key_header: "X-Api-Key",
                api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
                headers: [
                    {
                        key: 'Content-Type',
                        value: 'application/json',
                    },
                    {
                        key: 'Access-Control-Allow-Methods',
                        value: '*',
                    },
                ],
                body: {
                    orderNumber: orderNumber + '',
                    lineItemId: lineItemId + '',
                    webAppReprint: true,
                },
            };
        }
        //buildTicketResponse
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
         


        let response = await buildTicketResponse.json();

        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            if(changesYN == false)
            {
                setRebuildOpen(true);
                setCustomReprintButton(false);
                toast.success('Sent to the rebuild printer!', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                addTag(orderNumber);
            }
            else
            {
                if(typeName === 'none')
                {
                    setOrderType(response.type);
                }
                else
                {
                    setOrderType(typeName);
                }
                console.log(response);
                setCustomReprintButton(false);
                setInfoForForm(response);
                setSpecFormOpen(true);
            }
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };
    //warranty ticket from rebuild
    const createWarrantyTicketRebuild = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);

        console.log('OrderNumber :',orderNumber);
        let proxyObj = {
            url: process.env.REACT_APP_Rebuild_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Rebuild_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                webAppReprint: true,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
                    console.log("teehee");

        let response = await buildTicketResponse.json();

        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            console.log(response);
            setCustomReprintButton(false);
            setInfoForForm(response);
            setOrderType('Warranty');
            console.log(response);
            console.log("response above");
            setSpecFormOpen(true);
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };
    //warranty ticket from custom or stock
    const createWarrantyTicketNormal = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                webAppReprint: true,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );

        let response = await buildTicketResponse.json();
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setCustomReprintButton(false);
            setInfoForForm(response);
            setOrderType(response.type);
            setOrderType('Warranty');
            setSpecFormOpen(true);
            console.log(response);
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    //warranty ticket from custom or stock
    const createLostTicketNormal = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                webAppReprint: true,
                type: 'LostTicket'
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );

        let response = await buildTicketResponse.json();
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setCustomReprintButton(false);
            setInfoForForm(response);
            setOrderType(response.type);
            setOrderType('LostTicket');
            setSpecFormOpen(true);
            console.log(response);
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const createTryonTicketNormal = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                webAppReprint: true,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );

        let response = await buildTicketResponse.json();
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setCustomReprintButton(false);
            setInfoForForm(response);
            setOrderType(response.type);
            setOrderType('Tryon');
            setSpecFormOpen(true);
            console.log(response);
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };
    //temporary ticket from custom or stock
    const createTemporaryTicketNormal = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                webAppReprint: true,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );

        let response = await buildTicketResponse.json();
        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            setCustomReprintButton(false);
            setInfoForForm(response);
            setOrderType(response.type);
            setOrderType('Temporary');
            sendToRebuildPrinter(response);
            console.log(response);
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };
    //temporary ticket from rebuild
    const createTemporaryTicketRebuild = async (orderNumber, lineItemId) => {
        setCustomReprintButton(true);

        console.log('OrderNumber :',orderNumber);
        let proxyObj = {
            url: process.env.REACT_APP_Rebuild_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Rebuild_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderNumber: orderNumber + '',
                lineItemId: lineItemId + '',
                webAppReprint: true,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
                    console.log("teehee");

        let response = await buildTicketResponse.json();

        if (
            buildTicketResponse.status >= 200 &&
            buildTicketResponse.status <= 300
        ) {
            console.log(response);
            setCustomReprintButton(false);
            setInfoForForm(response);
            setOrderType('Temporary');
            console.log(response);
            sendToRebuildPrinter(response);
            console.log("response above");
            setSpecFormOpen(true);
        } else {
            toast.error('Problem getting the model specs. Contact IT.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };


    const addTag = async (orderNumber) => {
        let proxyObj = {
            url: `https://jk-boots.myshopify.com/admin/api/2022-04/orders/${orderNumber}.json?fields=id%2ctags`,
            method: 'GET',
            api_key_header: "X-Shopify-Access-Token",
            api_key_lookup_value: process.env.REACT_APP_Shopify_Access_Token,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
        };

        let orderInfoReturn = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        let orderJson = await orderInfoReturn.json();
        var tags = orderJson.order.tags;
        tags = tags + ', ricl';

        proxyObj = {
            url: `https://dev-jk-boots.myshopify.com/admin/api/2022-04/orders/${orderNumber}.json`,
            method: 'PUT',
            api_key_header: "X-Shopify-Access-Token",
            api_key_lookup_value: process.env.REACT_APP_Shopify_Access_Token,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                order: {
                    id: orderNumber,
                    tags: tags,
                },
            },
        };

        orderInfoReturn = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (orderInfoReturn.status < 300 && orderInfoReturn.status >= 200) {
            toast.success('\'ricl\' tag added to the order.', {
                position: 'top-center',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            toast.error('Couldn\'t add the \'ricl\' tag to the order.', {
                position: 'top-center',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };



    const sendToRebuildPrinter = async (iff) => {
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                arch: iff.arch + '',
                color: iff.color + '', 
                customColor: iff.customColor + '',
                customerName: iff.customerName + '',
                date: iff.date + '',
                completionDate: iff.completionDate ? iff.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: iff.hardware + '',
                heelLift: iff.heelLift + '',
                heelShape: iff.heelShape + '',
                height: iff.height + '',
                id: iff.id + '',
                orderNumber: iff.id + '', //yes, this should be the order id even though the field is called orderNumber. yes, build tickets needs to be rewritten.
                inventoryStatus: 'Temporary',
                eyeletRepair: iff.eyeletRepair + '',
                labelCount: 1,
                last: iff.last + '',
                lineItemId: iff.lineItemId + '',
                lineItemPosition: iff.lineItemPosition,
                lining: iff.lining + '',
                logo: iff.logo + '',
                midSole: iff.midSole + '',
                name: iff.name + '',
                notes: iff.notes + '',
                orderNumberName: iff.orderNumberName + '',
                otherGoods: iff.otherGoods + '',
                otherRepair: iff.otherRepair + '',
                patch: iff.patch + '',
                patchRepair: iff.patchRepair + '',
                productQtyCount: 1,
                productQtyCountTotal: 1,
                quarters: iff.quarters + '',
                reprint: true,
                reprintBy: iff.reprintBy,
                shank: iff.shank + '',
                side: iff.side + '',
                sideSheath: iff.sideSheath + '',
                sideSheathText: iff.sideSheathText + '',
                size: iff.size + '',
                sizeLeft: iff.sizeLeft + '',
                sizeRight: iff.sizeRight + '',
                sole: iff.sole + '',
                stitch: iff.stitch + '',
                style: iff.style + '',
                ticketVersionNumber: 1,
                //totalLineItems: totalLineItems,
                timeline: iff.timeline + '',
                toe: iff.toe + '',
                tongues: iff.tongues + '',
                type: 'Temporary',
                wedge: iff.wedge + '',
            },
        };
        //console.log("omnoomnoomnomno")
        console.log(proxyObj);
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={
                <Box>
                    <Button style={{width: 375}}>{lineItem.name}</Button>
                </Box>
            }
        >
            <Modal.Header>{`${lineItem.name}`}</Modal.Header>
            <Modal.Content>
                <Segment basic textAlign='center'>
                    <Segment basic> 
                        {lineItem.product_id === 4158696226914 || // rebuild
                        lineItem.product_id === 4158314446946 || //new soles
                        lineItem.product_id === 7668210467014 || // new heels
                        lineItem.product_id === 7724278382790 ? (// general repairs
                            <Box>
                                <Button
                                    onClick={() => createRebuild(orderNumber, lineItem.id)}
                                    color='teal'
                                >
                                    Print {lineItem.name} Ticket
                                </Button>
                                <Button
                                    onClick={() => getBuildTicketSpecs(orderNumber, lineItem.id)}
                                    color='red'
                                >
                                    Reprint with changes
                                </Button>
                                <Button
                                    onClick={() => createWarrantyTicketNormal(orderNumber, lineItem.id)}
                                    color='purple'
                                >
                                    Warranty Ticket
                                </Button>
                                <Button
                                    onClick={() => createTemporaryTicketNormal(orderNumber, lineItem.id)}
                                    color='orange'
                                >
                                    Temporary Ticket
                                </Button>
                                <Button
                                    onClick={() => createLostTicketNormal(orderNumber, lineItem.id)}
                                    color='yellow'
                                >
                                    Lost Ticket
                                </Button>
                            </Box>
                            
                        )// all of the custom boots
                         : 
                        lineItem.product_id === 7741656432838 || //custom arctic 2.0
                        lineItem.product_id === 7741656531142 || //custom caulk logger 2.0
                        lineItem.product_id === 7741656596678 || //custom climber 2.0
                        lineItem.product_id === 7741656629446 || //custom fire inlander 2.0
                        lineItem.product_id === 7741656727750 || //custom forefront 2.0 //not in dynamo db!!!!!!!!!!!!
                        lineItem.product_id === 7741656760518 || //custom harvester 2.0
                        lineItem.product_id === 7741656858822 || //custom ironhide 2.0
                        lineItem.product_id === 7741656924358 || //custom o.t. 2.0
                        lineItem.product_id === 7741657022662 || //custom station boot 2.0
                        lineItem.product_id === 7741657088198 || //custom superduty 2.0
                        lineItem.product_id === 7741657186502    //custom western packer 2.0
                        ?(
                            <Box>
                                <Button
                                    onClick={() => sendToBuildTicket(orderNumber, lineItem.id)}
                                    color='orange'
                                >
                                    Reprint Ticket
                                </Button>
                                <Button
                                    color='orange'
                                    onClick={() => getBuildTicketSpecs(orderNumber, lineItem.id)}
                                >
                                    Reprint with changes
                                </Button>
                                <Button
                                    onClick={() => createTryonTicketNormal(orderNumber, lineItem.id)}
                                    color='orange'
                                >
                                    Try On Ticket
                                </Button>
                                <Button
                                    onClick={() => createWarrantyTicketNormal(orderNumber, lineItem.id)}
                                    color='orange'
                                >
                                    Warranty Ticket
                                </Button>
                                <Button
                                    onClick={() => createTemporaryTicketNormal(orderNumber, lineItem.id)}
                                    color='orange'
                                >
                                    Temporary Ticket
                                </Button>
                                <Button
                                    onClick={() => createLostTicketNormal(orderNumber, lineItem.id)}
                                    color='yellow'
                                >
                                    Lost Ticket
                                </Button>
                            </Box>


                        ) // all of the custom boots
                        : lineItem.product_id === 7610903298246 || //custom arctic
                       lineItem.product_id === 7741656432838 || //custom arctic 2.0
                       lineItem.product_id === 7648052478150 || //custom caulk logger
                       lineItem.product_id === 7741656531142 || //custom caulk logger 2.0
                       lineItem.product_id === 7610957234374 || //custom climber
                       lineItem.product_id === 7741656596678 || //custom climber 2.0
                       lineItem.product_id === 7610901496006 || //custom fire inlander
                       lineItem.product_id === 7741656629446 || //custom fire inlander 2.0
                       lineItem.product_id === 7610897072326 || //custom forefront
                       lineItem.product_id === 7741656727750 || //custom forefront 2.0
                       lineItem.product_id === 7610946257094 || //custom harvester
                       lineItem.product_id === 7741656760518 || //custom harvester 2.0
                       lineItem.product_id === 7610950811846 || //custom ironhide
                       lineItem.product_id === 7741656858822 || //custom ironhide 2.0
                       lineItem.product_id === 7610894483654 || //custom o.t.
                       lineItem.product_id === 7741656924358 || //custom o.t. 2.0
                       lineItem.product_id === 7157539963078 || //custom station boot
                       lineItem.product_id === 7741657022662 || //custom station boot 2.0
                       lineItem.product_id === 7610383990982 || //custom superduty
                       lineItem.product_id === 7741657088198 || //custom superduty 2.0
                       lineItem.product_id === 7610908147910 || //custom western packer
                       lineItem.product_id === 7741657186502    //custom western packer 2.0
                       ?(
                           <Box>
                               <Button
                                   onClick={() => sendToBuildTicket(orderNumber, lineItem.id)}
                                   color='green'
                               >
                                   Reprint Ticket
                               </Button>
                               <Button
                                   color='red'
                                   onClick={() => getBuildTicketSpecs(orderNumber, lineItem.id)}
                               >
                                   Reprint with changes
                               </Button>
                               <Button
                                   onClick={() => createTryonTicketNormal(orderNumber, lineItem.id)}
                                   color='blue'
                               >
                                   Try On Ticket
                               </Button>
                               <Button
                                   onClick={() => createWarrantyTicketNormal(orderNumber, lineItem.id)}
                                   color='purple'
                               >
                                   Warranty Ticket
                               </Button>
                               <Button
                                    onClick={() => createTemporaryTicketNormal(orderNumber, lineItem.id)}
                                   color='orange'
                               >
                                   Temporary Ticket
                               </Button>
                               <Button
                                   onClick={() => createLostTicketNormal(orderNumber, lineItem.id)}
                                   color='yellow'
                               >
                                   Lost Ticket
                               </Button>
                           </Box>


                       ): (
                            <Box>
                                <Button
                                    onClick={() => sendToBuildTicket(orderNumber, lineItem.id)}
                                    color='green'
                                >
                                    Reprint Ticket
                                </Button>
                                <Button
                                    color='red'
                                    onClick={() => getBuildTicketSpecs(orderNumber, lineItem.id)}
                                >
                                    Reprint with changes
                                </Button>
                                <Button
                                    onClick={() => createWarrantyTicketNormal(orderNumber, lineItem.id)}
                                    color='purple'
                                >
                                    Warranty Ticket
                                </Button>
                                <Button
                                     onClick={() => createTemporaryTicketNormal(orderNumber, lineItem.id)}
                                    color='orange'
                                >
                                    Temporary Ticket
                                </Button>
                                <Button
                                    onClick={() => createLostTicketNormal(orderNumber, lineItem.id)}
                                    color='yellow'
                                >
                                    Lost Ticket
                                </Button>
                            </Box>

                        )}
                        {specFormOpen ? (
                            <Segment basic>
                                {orderType === 'Custom' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Custom"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Crazy-Custom' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"CrazyCustom"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Belt' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Belt"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === '' || orderType === 'Stock' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Stock"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType=== 'Rebuild' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Rebuild"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Reheel' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Reheel"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Resole' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Resole"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'General'? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"General"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Warranty' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Warranty"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'LostTicket' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"LostTicket"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Tryon' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Tryon"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                 {orderType === 'CrazyCustom' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"CrazyCustom"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                            </Segment>
                        ) : (
                            <Segment basic>
                                {customReprintButton ? (
                                    <Dimmer inverted active>
                                       <Loader/>
                                    </Dimmer>
                                ) : null}
                            </Segment>
                        )}
                    </Segment>
                </Segment>
            </Modal.Content>
        </Modal>
    );
};
