import {React, useState} from 'react';
import {Dropdown, Segment} from 'semantic-ui-react';

const toeOptions = [
    {
        key: 'Regular',
        value: 'Regular',
        text: 'Regular',
    },
    {
        key: 'Celastic stiff toe',
        value: 'Celastic stiff toe',
        text: 'Celastic stiff toe',
    },
    {
        key: 'Leather Toe Cap',
        value: 'Leather Toe Cap',
        text: 'Leather Toe Cap',
    },
    {
        key: 'Safety Toe',
        value: 'Safety Toe',
        text: 'Safety Toe',
    },
    {
        key: 'Composite',
        value: 'Composite',
        text: 'Composite',
    },
    {
        key: 'Steel Toe',
        value: 'Steel Toe',
        text: 'Steel Toe',
    },
    {
        key: 'Select toe type',
        value: '',
        text: 'Select toe type',
    },
];

export const LiftLeftFootDropdown = ({liftLeftFoot, setLiftLeftFoot}) => {
    return (
        <Dropdown
            placeholder='Select lift for left foot'
            value={liftLeftFoot}
            onChange={(_, {value}) => setLiftLeftFoot(value)}
            fluid
            selection
            options={BlankFixer(liftLeftFoot, [
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                {
                    key: '0.25"',
                    value: '0.25"',
                    text: '0.25"',
                },
                {
                    key: '0.5"',
                    value: '0.5"',
                    text: '0.5"',
                },
                {
                    key: '0.75"',
                    value: '0.75"',
                    text: '0.75"',
                },
                {
                    key: '1"',
                    value: '1"',
                    text: '1"',
                },
                {
                    key: '1.25"',
                    value: '1.25"',
                    text: '1.25"',
                },
                {
                    key: '1.5"',
                    value: '1.5"',
                    text: '1.5"',
                },
            ])}
        />
    );
};

export const LiftRightFootDropdown = ({liftRightFoot, setLiftRightFoot}) => {
    return (
        <Dropdown
            placeholder='Select lift for right foot'
            value={liftRightFoot}
            onChange={(_, {value}) => setLiftRightFoot(value)}
            fluid
            selection
            options={BlankFixer(liftRightFoot, [
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                {
                    key: '0.25"',
                    value: '0.25"',
                    text: '0.25"',
                },
                {
                    key: '0.5"',
                    value: '0.5"',
                    text: '0.5"',
                },
                {
                    key: '0.75"',
                    value: '0.75"',
                    text: '0.75"',
                },
                {
                    key: '1"',
                    value: '1"',
                    text: '1"',
                },
                {
                    key: '1.25"',
                    value: '1.25"',
                    text: '1.25"',
                },
                {
                    key: '1.5"',
                    value: '1.5"',
                    text: '1.5"',
                },
            ])}
        />
    );
};

export const SideDropdown = ({side, setSide}) => {
    return (
        <Dropdown
            placeholder='Select side'
            value={side}
            onChange={(_, {value}) => setSide(value)}
            fluid
            selection
            options={BlankFixer(side, [
                {
                    key: 'Full Rough',
                    value: 'Full Rough',
                    text: 'Full Rough',
                },
                {
                    key: 'Full Smooth',
                    value: 'Full Smooth',
                    text: 'Full Smooth',
                },
                {
                    key: 'Set side dropdown',
                    value: '',
                    text: 'Set side dropdown',
                },
            ])}
        />
    );
};

export const HeelPosition = ({heelPosition, setHeelPosition}) => {
    return (
        <Dropdown
            placeholder='Select heel position'
            value={heelPosition}
            onChange={(_, {value}) => setHeelPosition(value)}
            fluid
            selection
            options={BlankFixer(heelPosition, [
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'Heels Click In',
                    value: 'Heels Click In',
                    text: 'Heels Click In',
                },
                {
                    key: 'Heels Click Out',
                    value: 'Heels Click Out',
                    text: 'Heels Click Out',
                },
            ])}
        />
    );
};

export const StitchingRowsDropdown = ({stitchingRows, setStitchingRows}) => {
    return (
        <Dropdown
            placeholder='Select stitching rows'
            value={stitchingRows}
            onChange={(_, {value}) => setStitchingRows(value)}
            fluid
            selection
            options={BlankFixer(stitchingRows, [
                {
                    key: 'One row',
                    value: 'One row',
                    text: 'One row',
                },
                {
                    key: 'Two rows',
                    value: 'Two rows',
                    text: 'Two rows',
                },
            ])}
        />
    );
};

export const SimpleSideDropdown = ({side, setSide}) => {
    return (
        <Dropdown
            placeholder='Select side'
            value={side}
            onChange={(_, {value}) => setSide(value)}
            fluid
            selection
            options={BlankFixer(side, [
                {
                    key: 'RGH',
                    value: 'RGH',
                    text: 'Rough',
                },
                {
                    key: 'SM',
                    value: 'SM',
                    text: 'Smooth',
                },
            ])}
        />
    );
};

export const StitchColorDropdown = ({color, setColor}) => {
    return (
        <Dropdown
            placeholder='Select color'
            value={color}
            onChange={(_, {value}) => setColor(value)}
            fluid
            selection
            options={BlankFixer(color, [
                {
                    key: 'Black (Technora)',
                    value: 'Black (Technora)',
                    text: 'Black',
                },
                {
                    key: 'Tan',
                    value: 'Tan',
                    text: 'Tan',
                },
            ])}
        />
    );
};

export const ShortenedSideDropdown = ({side, setSide}) => {
    return (
        <Dropdown
            placeholder='Select side'
            value={side}
            onChange={(_, {value}) => setSide(value)}
            fluid
            selection
            options={BlankFixer(side, [
                {
                    key: 'Rough',
                    value: 'Rough',
                    text: 'Rough',
                },
                {
                    key: 'Smooth',
                    value: 'Smooth',
                    text: 'Smooth',
                },
                {
                    key: 'Set side dropdown',
                    value: '',
                    text: 'Set side dropdown',
                },
            ])}
        />
    );
};

export const BeltColorDropdown = ({color, setColor}) => {
    return (
        <Dropdown
            placeholder='Set color'
            value={color}
            onChange={(_, {value}) => setColor(value)}
            fluid
            selection
            options={BlankFixer(color, [
                {key: 'Bison', value: 'Bison', text: 'Bison'},
                {
                    key: 'Mocha',
                    value: 'Mocha',
                    text: 'Mocha',
                },
                {key: 'Brown', value: 'Brown', text: 'Brown'},
                {
                    key: 'Redwood',
                    value: 'Redwood',
                    text: 'Redwood',
                },
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {
                    key: 'Set belt color',
                    value: '',
                    text: 'Set belt color',
                },
            ])}
        />
    );
};

export const ColorDropdown = ({color, setColor}) => {
    return (
        <Dropdown
            placeholder='Set color'
            value={color}
            onChange={(_, {value}) => setColor(value)}
            fluid
            selection
            options={BlankFixer(color, [
                {key: 'Gray', value: 'Gray', text: 'Gray'},
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {key: 'Brown', value: 'Brown', text: 'Brown'},
                {
                    key: 'Mocha',
                    value: 'Mocha',
                    text: 'Mocha',
                },
                {
                    key: 'Redwood',
                    value: 'Redwood',
                    text: 'Redwood',
                },
                {
                    key: 'Bison',
                    value: 'Bison',
                    text: 'Bison',
                },
                {
                    key: 'Mahogany',
                    value: 'Mahogany',
                    text: 'Mahogany',
                },
                {
                    key: 'Set color',
                    value: '',
                    text: 'Set color',
                },
                {
                    key: 'See notes field',
                    value: 'See notes field',
                    text: 'See notes field (do not touch)',
                },
            ])}
        />
    );
};

export const HeightDropdown = ({height, setHeight}) => {
    return (
        <Dropdown
            placeholder='Set height'
            value={height}
            onChange={(_, {value}) => setHeight(value)}
            fluid
            selection
            options={BlankFixer(height, [
                {key: '6"', value: '6"', text: '6"'},
                {
                    key: '8"',
                    value: '8"',
                    text: '8"',
                },
                {key: '10"', value: '10"', text: '10"'},
                {
                    key: '12"',
                    value: '12"',
                    text: '12"',
                },
                {
                    key: '14"',
                    value: '14"',
                    text: '14"',
                },
                {
                    key: '16"',
                    value: '16"',
                    text: '16"',
                },
                {
                    key: '18"',
                    value: '18"',
                    text: '18"',
                },
                {
                    key: '20"',
                    value: '20"',
                    text: '20"',
                },
                {
                    key: 'Select height',
                    value: '',
                    text: 'Select height',
                },
            ])}
        />
    );
};

export const ToeDropdown = ({toe, setToe}) => {
    return (
        <Dropdown
            placeholder='Set toe'
            fluid
            selection
            options={BlankFixer(toe, [
                {
                    key: 'Soft',
                    value: 'Soft',
                    text: 'Soft',
                },
                {
                    key: 'Fiberglass Toe',
                    value: 'Fiberglass Toe',
                    text: 'Fiberglass Toe',
                },
                {
                    key: 'Celastic stiff toe',
                    value: 'Celastic stiff toe',
                    text: 'Celastic stiff toe',
                },
                {
                    key: 'Leather Toe Cap',
                    value: 'Leather Toe Cap',
                    text: 'Leather Toe Cap',
                },
                {
                    key: 'Safety Toe',
                    value: 'Safety Toe',
                    text: 'Safety Toe',
                },
                {
                    key: 'Composite',
                    value: 'Composite',
                    text: 'Composite',
                },
                {
                    key: 'Steel Toe',
                    value: 'Steel Toe',
                    text: 'Steel Toe',
                },
                {
                    key: 'Select toe type',
                    value: '',
                    text: 'Select toe type',
                },
            ])}
            value={toe}
            onChange={(_, {value}) => setToe(value)}
        />
    );
};

export const TimelineDropdown = ({timeline, setTimeline}) => {
    return (
        <Dropdown
            placeholder='Set timeline'
            value={timeline}
            onChange={(_, {value}) => setTimeline(value)}
            fluid
            selection
            options={BlankFixer(timeline, [
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'Expedited',
                    value: 'Expedited',
                    text: 'Expedited',
                },
                {
                    key: 'Select timeline',
                    value: '',
                    text: 'Select timeline',
                },
            ])}
        />
    );
};

export const EHRatingDropdown = ({ehRating, setEHRating}) => {
    return (
        <Dropdown
            placeholder='Set EH rating'
            value={ehRating}
            onChange={(_, {value}) => setEHRating(value)}
            fluid
            selection
            options={BlankFixer(ehRating, [
                {
                    key: 'Yes',
                    value: 'Yes',
                    text: 'Yes',
                },
                {
                    key: 'No',
                    value: 'No',
                    text: 'No',
                },
                {
                    key: 'Select style',
                    value: '',
                    text: 'Select style',
                },
            ])}
        />
    );
};

export const ArchPadDropdown = ({archPad, setArchPad}) => {
    return (
        <Dropdown
            placeholder='Set arch pad'
            value={archPad}
            onChange={(_, {value}) => setArchPad(value)}
            fluid
            selection
            options={BlankFixer(archPad, [
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'Extra High',
                    value: 'Extra High',
                    text: 'Extra High',
                },
            ])}
        />
    );
};

export const StyleDropdown = ({style, setStyle}) => {
    return (
        <Dropdown
            placeholder='Set style'
            value={style}
            onChange={(_, {value}) => setStyle(value)}
            fluid
            selection
            options={BlankFixer(style, [
                //{
                //  key: 'Regular *recommended',
                //  value: 'Regular * recommended', // causing it to print with recommended bit on it
                //  text: 'Regular',
                //},
                {
                    key: 'Regular',
                    value: 'Regular',
                    text: 'Regular',
                },
                {
                    key: 'LTT',
                    value: 'LTT',
                    text: 'LTT',
                },
                {
                    key: 'Plain',
                    value: 'Plain',
                    text: 'Plain',
                },
                {
                    key: 'Moc Toe',
                    value: 'Moc Toe',
                    text: 'Moc Toe',
                },
                {
                    key: 'Select style',
                    value: '',
                    text: 'Select style',
                },
            ])}
        />
    );
};

export const FullSideDropdown = ({side, setSide}) => {
    return (
        <Dropdown
            placeholder='Set side'
            value={side}
            onChange={(_, {value}) => setSide(value)}
            fluid
            selection
            options={BlankFixer(side, [
                {
                    key: 'Full Smooth',
                    value: 'Full Smooth',
                    text: 'Full Smooth',
                },
                {
                    key: 'F/H',
                    value: 'F/H',
                    text: 'F/H',
                },
                {
                    key: 'Full Rough',
                    value: 'Full Rough',
                    text: 'Full Rough',
                },
                {
                    key: 'See notes field',
                    value: 'See notes field',
                    text: 'See notes field',
                },
                {
                    key: 'Select side',
                    value: '',
                    text: 'Select side',
                },
            ])}
        />
    );
};

export const LogoDropdown = ({logo, setLogo}) => {
    return (
        <Dropdown
            placeholder='Set logo'
            value={logo}
            onChange={(_, {value}) => setLogo(value)}
            fluid
            selection
            options={BlankFixer(logo, [
                {
                    key: 'Flag logo Left Boot Only',
                    value: 'Flag logo Left Boot Only',
                    text: 'Flag logo Left Boot Only',
                },
                {
                    key: 'Custom logo Left Boot Only',
                    value: 'Custom logo Left Boot Only',
                    text: 'Custom logo Left Boot Only',
                },
                {
                    key: 'Select logo',
                    value: '',
                    text: 'Select logo',
                },
            ])}
        />
    );
};

export const RolledTopDropdown = ({rolledTop, setRolledTop}) => {
    return (
        <Dropdown
            placeholder='Set rolled top'
            value={rolledTop}
            onChange={(_, {value}) => setRolledTop(value)}
            fluid
            selection
            options={BlankFixer(rolledTop, [
                {
                    key: 'None',
                    value: 'None',
                    text: 'None',
                },
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {
                    key: 'Brown',
                    value: 'Brown',
                    text: 'Brown',
                },
                {
                    key: 'Gray',
                    value: 'Gray',
                    text: 'Gray',
                }
            ])}
        />
    );
};

export const TopHardwareDropdown = ({topHardware, setTopHardware}) => {
    return (
        <Dropdown
            placeholder='Set top hardware'
            value={topHardware}
            onChange={(_, {value}) => setTopHardware(value)}
            fluid
            selection
            options={BlankFixer(topHardware, [
                {
                    key: 'Eyelet',
                    value: 'Eyelet',
                    text: 'Eyelet',
                },
                {
                    key: 'Speed Hooks',
                    value: 'Speed Hooks',
                    text: 'Speed Hooks',
                },
            ])}
        />
    );
};

export const TongueDropdown = ({tongue, setTongue}) => {
    return (
        <Dropdown
            placeholder='Set tongue'
            value={tongue}
            onChange={(_, {value}) => setTongue(value)}
            fluid
            selection
            options={BlankFixer(tongue, [
                {
                    key: 'Enclosed',
                    value: 'Enclosed',
                    text: 'Enclosed',
                },
                {
                    key: 'Open',
                    value: 'Open',
                    text: 'Open',
                },
            ])}
        />
    );
};

export const TongueRepairDropdown = ({tongues, setTongues}) => {
    return (
        <Dropdown
            placeholder='Set tongue'
            value={tongues}
            onChange={(_, {value}) => setTongues(value)}
            fluid
            selection
            options={BlankFixer(tongues, [
                {
                    key: 'Refurbish (patch minor damage/small holes)',
                    value: 'Refurbish (patch minor damage/small holes)',
                    text: 'Refurbish (patch minor damage/small holes)',
                },
                {
                    key: 'Replace Entire Tongue (major damage/large holes)',
                    value: 'Replace Entire Tongue (major damage/large holes)',
                    text: 'Replace Entire Tongue (major damage/large holes)',
                },
            ])}
        />
    );
};

export const MidsoleDropdown = ({midSole, setMidSole}) => {
    return (
        <Dropdown
            placeholder='Set midsole'
            value={midSole}
            onChange={(_, {value}) => setMidSole(value)}
            fluid
            selection
            options={BlankFixer(midSole, [
                {
                    key: 'Flexy',
                    value: 'Flexy',
                    text: 'Flexy',
                },
                {
                    key: 'Full',
                    value: 'Full',
                    text: 'Full',
                },
                {
                    key: 'Full + Flexy',
                    value: 'Full + Flexy',
                    text: 'Full + Flexy',
                },
                {
                    key: 'Double',
                    value: 'Double',
                    text: 'Double',
                },
            ])}
        />
    );
};

export const SecondarySoleDropdown = ({secondarySole, setSecondarySole}) => {
    return (
        <Dropdown
            placeholder='Set secondary sole'
            value={secondarySole}
            onChange={(_, {value}) => setSecondarySole(value)}
            fluid
            selection
            options={BlankFixer(secondarySole, [
                {
                    key: 'Sierra',
                    value: 'Sierra',
                    text: 'Sierra',
                },
                {
                    key: 'Newporter',
                    value: 'Newporter',
                    text: 'Newporter',
                },
                {
                    key: '430',
                    value: '430',
                    text: '430',
                },
                {
                    key: 'Vbar',
                    value: 'Vbar',
                    text: 'Vbar',
                },
                {
                    key: 'Kletterlift',
                    value: 'Kletterlift',
                    text: 'Kletterlift',
                },
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
            ])}
        />
    );
};

export const InnerShankDropdown = ({innerShank, setInnerShank}) => {
    return (
        <Dropdown
            placeholder='Set inner shank'
            value={innerShank}
            onChange={(_, {value}) => setInnerShank(value)}
            fluid
            selection
            options={BlankFixer(innerShank, [
                {
                    key: 'Leather',
                    value: 'Leather',
                    text: 'Leather',
                },
                {
                    key: 'Steel',
                    value: 'Steel',
                    text: 'Steel',
                },
                {
                    key: 'Leather & Steel',
                    value: 'Leather & Steel',
                    text: 'Leather & Steel',
                },
            ])}
        />
    );
};

export const WedgeDropdown = ({wedge, setWedge}) => {
    return (
        <Dropdown
            placeholder='Set wedge'
            value={wedge}
            onChange={(_, {value}) => setWedge(value)}
            fluid
            selection
            options={BlankFixer(wedge, [
                {
                    key: '1/8" outer"',
                    value: '1/8" outer',
                    text: '1/8" outer',
                },
                {
                    key: '1/8" inner',
                    value: '1/8" inner',
                    text: '1/8" inner',
                },
                /* {
                   key: '1/4 wedge outside',
                   value: '1/4 wedge outside',
                   text: '1/4 wedge outside',
                 },
                 {
                   key: '1/4 wedge inside',
                   value: '1/4 wedge inside',
                   text: '1/4 wedge inside',
                 },*/
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
            ])}
        />
    );
};

export const EdgeStainColorDropdown = ({edgeStain, setEdgeStain}) => {
    return (
        <Dropdown
            placeholder='Set edge stain'
            value={edgeStain}
            onChange={(_, {value}) => setEdgeStain(value)}
            fluid
            selection
            options={BlankFixer(edgeStain, [
                {
                    key: 'Standard',
                    value: 'Standard',
                    text: 'Standard',
                },
                {
                    key: 'Orange',
                    value: 'Orange',
                    text: 'Orange',
                },
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {
                    key: 'Brown',
                    value: 'Brown',
                    text: 'Brown',
                },
                {
                    key: 'Natural',
                    value: 'Natural',
                    text: 'Natural',
                },
            ])}
        />
    );
};

export const EyeletsDropdown = ({eyelets, setEyelets}) => {
    return (
        <Dropdown
            placeholder='Set eyelets'
            value={eyelets}
            onChange={(_, {value}) => setEyelets(value)}
            fluid
            selection
            options={BlankFixer(eyelets, [
                {
                    key: '4',
                    value: '4',
                    text: '4',
                },
                {
                    key: '5',
                    value: '5',
                    text: '5',
                },
                {
                    key: '6',
                    value: '6',
                    text: '6',
                },
                {
                    key: '7',
                    value: '7',
                    text: '7',
                },
                {
                    key: '8',
                    value: '8',
                    text: '8',
                },
                {
                    key: 'All',
                    value: 'All',
                    text: 'All',
                },
            ])}
        />
    );
};

export const LiningDropdown = ({lining, setLining}) => {
    return (
        <Dropdown
            placeholder='Set lining'
            value={lining}
            onChange={(_, {value}) => setLining(value)}
            fluid
            selection
            options={BlankFixer(lining, [
                {
                    key: 'Thinsulate',
                    value: 'Thinsulate',
                    text: 'Thinsulate',
                },
                {
                    key: 'Thinsulate - below zero',
                    value: 'Thinsulate - below zero',
                    text: 'Thinsulate - below zero',
                },
                {
                    key: 'No insulation',
                    value: '',
                    text: 'No insulation',
                },
            ])}
        />
    );
};

export const BlackEditionLiningDropdown = ({lining, setLining}) => {
    return (
        <Dropdown
            placeholder='Set lining'
            value={lining}
            onChange={(_, {value}) => setLining(value)}
            fluid
            selection
            options={BlankFixer(lining, [
                {
                    key: 'Thinsulate',
                    value: 'Thinsulate',
                    text: 'Thinsulate',
                },
                {
                    key: 'Thinsulate - below zero',
                    value: 'Thinsulate - below zero',
                    text: 'Thinsulate - below zero',
                },
                {
                    key: 'Elk Lining',
                    value: 'Elk Lining',
                    text: 'Elk Lining',
                },
                {
                    key: 'Fur',
                    value: 'Fur',
                    text: 'Fur',
                },
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
            ])}
        />
    );
};

export const HardwareDropdown = ({hardware, setHardware}) => {
    return (
        <Dropdown
            placeholder='Set hardware'
            value={hardware}
            onChange={(_, {value}) => setHardware(value)}
            fluid
            selection
            options={BlankFixer(hardware, [
                {
                    key: 'Brass',
                    value: 'Brass',
                    text: 'Brass',
                },
                {
                    key: 'Antique Brass',
                    value: 'Antique Brass',
                    text: 'Antique Brass',
                },
                {
                    key: 'Black',
                    value: 'Black',
                    text: 'Black',
                },
                {
                    key: 'Nickel',
                    value: 'Nickel',
                    text: 'Nickel',
                },
                {
                    key: 'Select hardware',
                    value: '',
                    text: 'Select hardware',
                },
            ])}
        />
    );
};

export const BeltHardwareDropdown = ({hardware, setHardware}) => {
    return (
        <Dropdown
            placeholder='Set hardware'
            value={hardware}
            onChange={(_, {value}) => setHardware(value)}
            fluid
            selection
            options={BlankFixer(hardware, [
                {
                    key: 'Antique Brass',
                    value: 'Antique Brass',
                    text: 'Antique Brass',
                },
                {
                    key: 'Nickel',
                    value: 'Nickel',
                    text: 'Nickel',
                },
                {
                    key: 'Select belt hardware',
                    value: '',
                    text: 'Select belt hardware',
                },
            ])}
        />
    );
};
//not used by ticket
export const SideSheathDropdown = ({setDropdownSideSheathText}) => {
    return (
        <Dropdown
            placeholder='Set side sheath'
            onChange={(_, {value}) => setDropdownSideSheathText(value)}
            fluid
            selection
            options={[
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
                {
                    key: 'Outside Right Leg 1.5" wide X 3" tall',
                    value: 'Outside Right Leg 1.5" wide X 3" tall',
                    text: 'Outside Right Leg 1.5" wide X 3" tall',
                },
                {
                    key: 'Outside Right Leg 2" wide X 3" tall',
                    value: 'Outside Right Leg 2" wide X 3" tall',
                    text: 'Outside Right Leg 2" wide X 3" tall',
                },
                {
                    key: 'Outside Right Leg 2" wide X 5" tall',
                    value: 'Outside Right Leg 2" wide X 5" tall',
                    text: 'Outside Right Leg 2" wide X 5" tall',
                },
                {
                    key: 'Inside Right Leg 1.5" wide X 3" tall',
                    value: 'Inside Right Leg 1.5" wide X 3" tall',
                    text: 'Inside Right Leg 1.5" wide X 3" tall',
                },
                {
                    key: 'Inside Right Leg 2" wide X 3" tall',
                    value: 'Inside Right Leg 2" wide X 3" tall',
                    text: 'Inside Right Leg 2" wide X 3" tall',
                },
                {
                    key: 'Inside Right Leg 2" wide X 5" tall',
                    value: 'Inside Right Leg 2" wide X 5" tall',
                    text: 'Inside Right Leg 2" wide X 5" tall',
                },
                {
                    key: 'Outside Left Leg 1.5" wide X 3" tall',
                    value: 'Outside Left Leg 1.5" wide X 3" tall',
                    text: 'Outside Left Leg 1.5" wide X 3" tall',
                },
                {
                    key: 'Outside Left Leg 2" wide X 3" tall',
                    value: 'Outside Left Leg 2" wide X 3" tall',
                    text: 'Outside Left Leg 2" wide X 3" tall',
                },
                {
                    key: 'Outside Left Leg 2" wide X 5" tall',
                    value: 'Outside Left Leg 2" wide X 5" tall',
                    text: 'Outside Left Leg 2" wide X 5" tall',
                },
                {
                    key: 'Inside Left Leg 1.5" wide X 3" tall',
                    value: 'Inside Left Leg 1.5" wide X 3" tall',
                    text: 'Inside Left Leg 1.5" wide X 3" tall',
                },
                {
                    key: 'Inside Left Leg 2" wide X 3" tall',
                    value: 'Inside Left Leg 2" wide X 3" tall',
                    text: 'Inside Left Leg 2" wide X 3" tall',
                },
                {
                    key: 'Inside Left Leg 2" wide X 5" tall',
                    value: 'Inside Left Leg 2" wide X 5" tall',
                    text: 'Inside Left Leg 2" wide X 5" tall',
                },
                {
                    key: 'Select sheath',
                    value: '',
                    text: 'Select sheath',
                },
            ]}
        />
    );
};

export const LeftSideSheathDropdown = ({
                                           dropdownText,
                                           setDropdownSideSheathText,
                                       }) => {
    return (
        <Dropdown
            placeholder='Set left side sheath'
            onChange={(_, {value}) => setDropdownSideSheathText(value)}
            fluid
            value={dropdownText}
            selection
            options={BlankFixer(dropdownText, [
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
                {
                    key: 'Left Leg, Left Side 1.5" wide X 3" tall',
                    value: 'Left Leg, Left Side 1.5" wide X 3" tall',
                    text: 'Left Leg, Left Side 1.5" wide X 3" tall',
                },
                {
                    key: 'Left Leg, Left Side 2" wide X 3" tall',
                    value: 'Left Leg, Left Side 2" wide X 3" tall',
                    text: 'Left Leg, Left Side 2" wide X 3" tall',
                },
                {
                    key: 'Left Leg, Left Side 2" wide X 5" tall',
                    value: 'Left Leg, Left Side 2" wide X 5" tall',
                    text: 'Left Leg, Left Side 2" wide X 5" tall',
                },
                {
                    key: 'Left Leg, Right Side 1.5" wide X 3" tall',
                    value: 'Left Leg, Right Side 1.5" wide X 3" tall',
                    text: 'Left Leg, Right Side 1.5" wide X 3" tall',
                },
                {
                    key: 'Left Leg, Right Side 2" wide X 3" tall',
                    value: 'Left Leg, Right Side 2" wide X 3" tall',
                    text: 'Left Leg, Right Side 2" wide X 3" tall',
                },
                {
                    key: 'Left Leg, Right Side 2" wide X 5" tall',
                    value: 'Left Leg, Right Side 2" wide X 5" tall',
                    text: 'Left Leg, Right Side 2" wide X 5" tall',
                },
                {
                    key: 'Select sheath',
                    value: '',
                    text: 'Select sheath',
                },
            ])}
            //"Left Leg, Left Side 2" wide X 5" tall"
        />
    );
};

export const RightSideSheathDropdown = ({
                                            dropdownText,
                                            setDropdownSideSheathText,
                                        }) => {
    return (
        <Dropdown
            placeholder='Set right side sheath'
            onChange={(_, {value}) => setDropdownSideSheathText(value)}
            value={dropdownText}
            fluid
            selection
            options={BlankFixer(dropdownText, [
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
                {
                    key: 'Right Leg, Right Side 1.5" wide X 3" tall',
                    value: 'Right Leg, Right Side 1.5" wide X 3" tall',
                    text: 'Right Leg, Right Side 1.5" wide X 3" tall',
                },
                {
                    key: 'Right Leg, Right Side 2" wide X 3" tall',
                    value: 'Right Leg, Right Side 2" wide X 3" tall',
                    text: 'Right Leg, Right Side 2" wide X 3" tall',
                },
                {
                    key: 'Right Leg, Right Side 2" wide X 5" tall',
                    value: 'Right Leg, Right Side 2" wide X 5" tall',
                    text: 'Right Leg, Right Side 2" wide X 5" tall',
                },
                {
                    key: 'Right Leg, Left Side 1.5" wide X 3" tall',
                    value: 'Right Leg, Left Side 1.5" wide X 3" tall',
                    text: 'Right Leg, Left Side 1.5" wide X 3" tall',
                },
                {
                    key: 'Right Leg, Left Side 2" wide X 3" tall',
                    value: 'Right Leg, Left Side 2" wide X 3" tall',
                    text: 'Right Leg, Left Side 2" wide X 3" tall',
                },
                {
                    key: 'Right Leg, Left Side 2" wide X 5" tall',
                    value: 'Right Leg, Left Side 2" wide X 5" tall',
                    text: 'Right Leg, Left Side 2" wide X 5" tall',
                },
                {
                    key: 'Select sheath',
                    value: '',
                    text: 'Select sheath',
                },
            ])}
        />
    );
};

export const PrinterDropdown = ({printer, setPrinter}) => {
    return (
        <Dropdown
            placeholder='Override printer'
            value={printer}
            onChange={(_, {value}) => setPrinter(value)}
            fluid
            selection
            //options={dropdownOptions}
            options={BlankFixer(printer, [
                {
                    key: 'None',
                    value: '',
                    text: 'None',
                },
                {
                    key: 'Stock',
                    value: 'Stock',
                    text: 'Stock',
                },
                {
                    key: 'NotStocked',
                    value: 'NotStocked',
                    text: 'NotStocked',
                },
                {
                    key: 'Custom',
                    value: 'Custom',
                    text: 'Custom',
                },
                {
                    key: 'Rebuild',
                    value: 'Rebuild',
                    text: 'Rebuild',
                },
                {
                    key: 'Tryon',
                    value: 'Tryon',
                    text: 'Try On',
                },
                {
                    key: 'Warranty',
                    value: 'Warranty',
                    text: 'Warranty',
                },
            ])}
        />
    );
};

export const OtherGoodsDropdown = ({otherGoods, setOtherGoods}) => {
    return (
        <Dropdown
            placeholder='Select status of other goods'
            value={otherGoods}
            onChange={(_, {value}) => setOtherGoods(value)}
            fluid
            selection
            options={BlankFixer(otherGoods, [
                {
                    key: 'Yes',
                    value: 'Yes',
                    text: 'Yes',
                },
                {
                    key: 'No',
                    value: '',
                    text: 'No',
                },
            ])}
        />
    );
};

export const StitchDropdown = ({stitch, setStitch}) => {
    return (
        <Dropdown
            placeholder='Select stitch'
            value={stitch}
            onChange={(_, {value}) => setStitch(value)}
            fluid
            selection
            options={BlankFixer(stitch, [
                {
                    key: 'Technora',
                    value: 'Technora',
                    text: 'Technora',
                },
                {
                    key: 'Select Stitch',
                    value: '',
                    text: 'Select Stitch',
                },
            ])}
        />
    );
};

export const ShankDropdown = ({shank, setShank}) => {
    return (
        <Dropdown
            placeholder='Select shank'
            value={shank}
            onChange={(_, {value}) => setShank(value)}
            fluid
            selection
            options={BlankFixer(shank, [
                {
                    key: 'Lineman Shank',
                    value: 'Lineman Shank',
                    text: 'Lineman Shank',
                },
                {
                    key: 'Thin Leather',
                    value: 'Thin Leather',
                    text: 'Thin Leather',
                },
                {
                    key: 'Select Shank',
                    value: '',
                    text: 'Select Shank',
                },
            ])}
        />
    );
};

export const PatchDropdown = ({patch, setPatch}) => {
    return (
        <Dropdown
            placeholder='Select patch'
            value={patch}
            onChange={(_, {value}) => setPatch(value)}
            fluid
            selection
            options={BlankFixer(patch, [
                {
                    key: 'Outside',
                    value: 'Outside',
                    text: 'Lineman Outside',
                },
                {
                    key: 'Inside',
                    value: 'Inside',
                    text: 'Lineman Inside',
                },
                {
                    key: 'Select Patch',
                    value: '',
                    text: 'Select Patch',
                },
            ])}
        />
    );
};

export const ArchDropdown = ({arch, setArch}) => {
    return (
        <Dropdown
            placeholder='Select arch'
            value={arch}
            onChange={(_, {value}) => setArch(value)}
            fluid
            selection
            options={BlankFixer(arch, [
                {
                    key: 'Standard',
                    value: 'Standard',
                    text: 'Standard',
                },
                {
                    key: 'Leather Over Steel',
                    value: 'Leather Over Steel',
                    text: 'Leather Over Steel',
                },
                {
                    key: 'NAJS',
                    value: 'NAJS',
                    text: 'NAJS',
                },
                {
                    key: 'Select arch',
                    value: '',
                    text: 'Select arch',
                },
            ])}
        />
    );
};

export const SoleDropdown = ({sole, setSole}) => {
    return (
        <Dropdown
            placeholder='Select sole'
            value={sole}
            onChange={(_, {value}) => setSole(value)}
            fluid
            selection
            options={BlankFixer(sole, [
                {
                    key: '100 RedX NFPA',//1
                    value: '100 RedX NFPA',
                    text: '100 RedX NFPA',
                },
                {
                    key: '100 Honey',//2
                    value: '100 Honey',
                    text: '100 Honey',
                },
                {
                    key: '100 WhiteX',//3
                    value: '100 WhiteX',
                    text: '100 WhiteX',
                },
                {
                    key: '4014 Wh. Chr.',//4
                    value: '4014 Wh. Chr.',
                    text: '4014 Wh. Chr.',
                },
                {
                    key: '4014 Blk. Chr.',//5
                    value: '4014 Blk. Chr.',
                    text: '4014 Blk. Chr.',
                },
                {
                    key: '4014 White flat',//6
                    value: '4014 White flat',
                    text: '4014 White flat',
                },
                {
                    key: '4014 Black flat',//7
                    value: '4014 Black flat',
                    text: '4014 Black flat',
                },
                {
                    key: '100 RedX Lugsole',//8
                    value: '100 RedX Lugsole',
                    text: '100 RedX Lugsole',
                },
                {
                    key: '100 Honey Lugsole',//9
                    value: '100 Honey Lugsole',
                    text: '100 Honey Lugsole',
                },
                {
                    key: '100 WhiteX Lugsole',//10
                    value: '100 WhiteX Lugsole',
                    text: '100 WhiteX Lugsole',
                },
                {
                    key: '430 Mini-lug',//11
                    value: '430 Mini-lug',
                    text: '430 Mini-lug',
                },
                {
                    key: '700 Bar',//12
                    value: '700 Bar',
                    text: '700 Bar',
                },
                {
                    key: '132® RedX',//13
                    value: '132® RedX',
                    text: '132® RedX',
                },
                {
                    key: '132® Honey',//14
                    value: '132® Honey',
                    text: '132® Honey',
                },
                {
                    key: '100 RedX Fire Hard',//15
                    value: '100 RedX Fire Hard',
                    text: '100 RedX Fire Hard',
                },
                {
                    key: '100 Honey Lug',//16
                    value: '100 Honey Lug',
                    text: '100 Honey Lug',
                },
                {
                    key: '100 WhiteX Lug',//17
                    value: '100 WhiteX Lug',
                    text: '100 WhiteX Lug',
                },
                {
                    key: '100® All-terrain',//18
                    value: '100® All-terrain',
                    text: '100® All-terrain',
                },
                {
                    key: '430® some tread/flat',//19
                    value: '430® some tread/flat',
                    text: '430® some tread/flat',
                },
                {
                    key: '700® smooth/flat',//20
                    value: '700® smooth/flat',
                    text: '700® smooth/flat',
                },
                {
                    key: 'Cork Caulk sole',//21
                    value: 'Cork Caulk sole',
                    text: 'Cork Caulk sole',
                },
                {
                    key: 'Asphalt',//22
                    value: 'Asphalt',
                    text: 'Asphalt',
                },
            ])}
        />
    );
};

export const BootModelsDropdown = ({bootModel, setBootModel}) => {
    return (
        <Dropdown
            placeholder='Select boot model'
            value={bootModel}
            onChange={(_, {value}) => setBootModel(value)}
            fluid
            selection
            options={BlankFixer(bootModel, [
                {
                    key: '300',
                    value: '300',
                    text: '300',
                },
                {
                    key: '300 (Safety Toe)',
                    value: '300 (Safety Toe)',
                    text: '300 (Safety Toe)',
                },
                {
                    key: '300X',
                    value: '300X',
                    text: '300X',
                },
                {
                    key: '300X (Safety Toe)',
                    value: '300X (Safety Toe)',
                    text: '300X (Safety Toe)',
                },
                {
                    key: 'Arctic No. 1',
                    value: 'Arctic No. 1',
                    text: 'Arctic No. 1',
                },
                {
                    key: 'Arctic No. 2',
                    value: 'Arctic No. 2',
                    text: 'Arctic No. 2',
                },
                {
                    key: 'Climber',
                    value: 'Climber',
                    text: 'Climber',
                },
                {
                    key: 'Climber (Safety Toe)',
                    value: 'Climber (Safety Toe)',
                    text: 'Climber (Safety Toe)',
                },
                {
                    key: 'Fire Inlander',
                    value: 'Fire Inlander',
                    text: 'Fire Inlander',
                },
                {
                    key: 'Fire Inlander LTT',
                    value: 'Fire Inlander LTT',
                    text: 'Fire Inlander LTT',
                },
                {
                    key: 'Fire Inlander S',
                    value: 'Fire Inlander S',
                    text: 'Fire Inlander S',
                },
                {
                    key: 'Forefront',
                    value: 'Forefront',
                    text: 'Forefront',
                },
                {
                    key: 'Forefront (Safety Toe)',
                    value: 'Forefront (Safety Toe)',
                    text: 'Forefront (Safety Toe)',
                },
                {
                    key: 'Forefront 8"',
                    value: 'Forefront 8"',
                    text: 'Forefront 8"',
                },
                {
                    key: 'Forefront 8" (Safety Toe)',
                    value: 'Forefront 8" (Safety Toe)',
                    text: 'Forefront 8" (Safety Toe)',
                },
                {
                    key: 'Harvester',
                    value: 'Harvester',
                    text: 'Harvester',
                },
                {
                    key: 'Honey Fire',
                    value: 'Honey Fire',
                    text: 'Honey Fire',
                },
                {
                    key: 'O.T.',
                    value: 'O.T.',
                    text: 'O.T.',
                },
                {
                    key: 'O.T. (Safety Toe)',
                    value: 'O.T. (Safety Toe)',
                    text: 'O.T. (Safety Toe)',
                },
                {
                    key: 'O.T. 6"',
                    value: 'O.T. 6"',
                    text: 'O.T. 6"',
                },
                {
                    key: 'O.T. 6" (Safety Toe)',
                    value: 'O.T. 6" (Safety Toe)',
                    text: 'O.T. 6" (Safety Toe)',
                },
                {
                    key: 'O.T. Pro',
                    value: 'O.T. Pro',
                    text: 'O.T. Pro',
                },
                {
                    key: 'O.T. Pro (Safety Toe)',
                    value: 'O.T. Pro (Safety Toe)',
                    text: 'O.T. Pro (Safety Toe)',
                },
                {
                    key: 'Superduty',
                    value: 'Superduty',
                    text: 'Superduty',
                },
                {
                    key: 'Superduty (Safety Toe)',
                    value: 'Superduty (Safety Toe)',
                    text: 'Superduty (Safety Toe)',
                },
                {
                    key: 'Superduty Honey (Safety Toe)',
                    value: 'Superduty Honey (Safety Toe)',
                    text: 'Superduty Honey (Safety Toe)',
                },
                {
                    key: 'Superduty S',
                    value: 'Superduty S',
                    text: 'Superduty S',
                },
                {
                    key: 'Superduty S (Safety Toe)',
                    value: 'Superduty S (Safety Toe)',
                    text: 'Superduty S (Safety Toe)',
                },
            ])}
        />
    );
};

export const BeltModelsDropdown = ({belt, setBelt}) => {
    return (
        <Dropdown
            placeholder='Select belt model'
            value={belt}
            onChange={(_, {value}) => setBelt(value)}
            fluid
            selection
            options={BlankFixer(belt, [
                {
                    key: 'Smooth Black Leather Belt',
                    value: 'Smooth Black Leather Belt',
                    text: 'Smooth Black Leather Belt',
                },
                {
                    key: 'Rough Black Leather Belt',
                    value: 'Rough Black Leather Belt',
                    text: 'Rough Black Leather Belt',
                },
                {
                    key: 'Smooth Brown Leather Belt',
                    value: 'Smooth Brown Leather Belt',
                    text: 'Smooth Brown Leather Belt',
                },
                {
                    key: 'Rough Brown Leather Belt',
                    value: 'Rough Brown Leather Belt',
                    text: 'Rough Brown Leather Belt',
                },
                {
                    key: 'Smooth Redwood Leather Belt',
                    value: 'Smooth Redwood Leather Belt',
                    text: 'Smooth Redwood Leather Belt',
                },
                {
                    key: 'Rough Redwood Leather Belt',
                    value: 'Rough Redwood Leather Belt',
                    text: 'Rough Redwood Leather Belt',
                },
                {
                    key: 'Smooth Mocha Leather Belt',
                    value: 'Smooth Mocha Leather Belt',
                    text: 'Smooth Mocha Leather Belt',
                },
                {
                    key: 'Rough Mocha Leather Belt',
                    value: 'Rough Mocha Leather Belt',
                    text: 'Rough Mocha Leather Belt',
                },
                {
                    key: 'Bison Leather Belt',
                    value: 'Bison Leather Belt',
                    text: 'Bison Leather Belt',
                },
                {
                    key: 'Rough Gray Leather Belt',
                    value: 'Rough Gray Leather Belt',
                    text: 'Rough Gray Leather Belt',
                },
                {
                    key: 'Smooth Gray Leather Belt',
                    value: 'Smooth Gray Leather Belt',
                    text: 'Smooth Gray Leather Belt',
                },
            ])}
        />
    );
};


const BlankFixer = (sentIn, dropDownOptions) => {

    if (sentIn == "")
        return dropDownOptions;
    if (sentIn == sentIn == "undefined")
        return dropDownOptions;
    let inDropdown = false;
    for (let x = 0; x < dropDownOptions.length; x++) {
        if (dropDownOptions[x].key == sentIn) {
            inDropdown = true;
        }
    }
    if (!inDropdown) {
        let helper = dropDownOptions.concat({
            key: sentIn,
            value: sentIn,
            text: "!!(NIOD) " + sentIn,
        })
        return helper;
    }
    return dropDownOptions;
};