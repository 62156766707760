import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';

import {
    Segment,
    Button,
    Header,
    Input,
    Loader,
    Dimmer,
    Form,
    Image,
} from 'semantic-ui-react';
import {Box} from 'theme-ui';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import {Login} from './login';
import {PrintTicketModal} from './new-ticket-modal';
import {OrderModal} from './order-modal/order_modal';
import {Footer} from './other/footer';
import JKLogo from './images/JK-logo.png';
import {Resources} from './order-modal/resources';

const App = () => {
    const [orderId, setOrderId] = useState('');
    const [displayLoader, setDisplayLoader] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [orderedDate, setOrderedDate] = useState('');
    const [orderArray, setOrderArray] = useState([]);
    const [showLineItems, setShowLineItems] = useState(false);
    const [lineItems, setLineItems] = useState([]);
    const [user, setUser] = useState({});
    const [reprintBy, setReprintBy] = useState('');
    const [displayNotFound, setDisplayNotFound] = useState(false);
    const [credential, setCredential] = useState('');
    const [showFulfilledItems, setShowFulfilledItems] = useState(false)
    var lineItemPosition = 0;

    const handleOrderSearch = async () => {
        if (orderId === '') return;
        setDisplayLoader(true);
        setShowLineItems(false);
        let proxyObj = {
            url: `https://jk-boots.myshopify.com/admin/api/2022-04/orders.json?name=${orderId}&status=any`,
            method: 'GET',
            api_key_header: "X-Shopify-Access-Token",
            api_key_lookup_value: process.env.REACT_APP_Shopify_Access_Token,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
        };

        let orderInfoReturn = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(proxyObj),
            }
        );
        let orderJson = await orderInfoReturn.json();
        if (orderJson.orders[0] === undefined) {
            setDisplayLoader(false);
            setDisplayNotFound(true);
        } else {
            setDisplayNotFound(false);
            setOrderArray(orderJson);
            setShowLineItems(true);
        }
    };

    const handleSignOut = () => {
        setUser({});
        setCredential('');
        toast.success('We are signed out!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
    };

    useEffect(() => {
        if (orderArray.length === 0) return;
        setLineItems(orderArray.orders[0].line_items);
        setOrderNumber(orderArray.orders[0].id);
        setOrderedDate(orderArray.orders[0].created_at);
        setCustomerName(
            orderArray.orders[0].customer.first_name +
            ' ' +
            orderArray.orders[0].customer.last_name
        );
        setDisplayLoader(false);
    }, [orderArray]);

    return (
        <div
            style={{
                display: 'flex',
                minHeight: '100vh',
                flexDirection: 'column',
                justifyContent: 'space-between',
                textAlign: 'center',
            }}
        >
            {Object.keys(user).length === 0 ? (
                <Login user={user} setUser={setUser} setCredential={setCredential} setReprintBy={setReprintBy}/>
            ) : (
                <Box>
                    <ToastContainer/>
                    <Image src={JKLogo} size='small' style={{margin: '0 auto'}}/>
                    <Header as='h1' textAlign='center'>
                        JK Boots Ticket Reprint
                    </Header>
                    <Box sx={{mb: 20}}>
                        <Button color='red' size='large' onClick={() => handleSignOut()}>
                            Sign Out
                        </Button>
                    </Box>
                    <Form onSubmit={() => handleOrderSearch()}>
                        <Form.Input
                            placeholder='Enter order number'
                            value={orderId}
                            size='huge'
                            style={{maxWidth: 300}}
                            onChange={(_, {value}) => setOrderId(value)}
                        />
                    </Form>
                    <Segment basic style={{width: 500, margin: '0 auto'}}>
                        {showLineItems ? (
                            <Box>
                                {lineItems.map(function (item) {
                                    lineItemPosition = lineItemPosition + 1;
                                    if (item.fulfillment_status == null || showFulfilledItems) {
                                        return (
                                            <Box key={item.id} sx={{margin: '10px'}}>
                                                <OrderModal
                                                    orderNumber={orderNumber}
                                                    lineItem={item}
                                                    lineItemPosition={lineItemPosition}
                                                    reprintBy={reprintBy}
                                                    credential={credential}
                                                />
                                            </Box>
                                        );
                                    }
                                })}
                                <PrintTicketModal
                                    customerName={customerName}
                                    orderNumber={orderNumber}
                                    orderId={orderId}
                                    totalLineItems={lineItems.length}
                                    orderedDate={orderedDate}
                                    reprintBy={reprintBy}
                                    credential={credential}
                                />
                            </Box>
                        ) : (
                            <Box>
                                {displayLoader ? (
                                    <Box sx={{height: '30px'}}>
                                        <Dimmer active inverted>
                                            <Loader/>
                                        </Dimmer>
                                    </Box>
                                ) : null}
                            </Box>
                        )}
                        {displayNotFound ? (
                            <Segment color='orange'>
                                No order found. Make sure order is not archived or enter another
                                one.
                            </Segment>
                        ) : null}
                    </Segment>
                    <br/>
                    <Box sx={{mb: 20}}>
                        <Button color='orange' size='medium' onClick={() => {
                            setShowFulfilledItems(!showFulfilledItems)
                        }}>
                            {
                                (() => {
                                    if (showFulfilledItems) {
                                        return "Hide fulfilled line items"
                                    } else {
                                        return "Show fulfilled line items"
                                    }
                                })()
                            }
                        </Button>
                    </Box>
                    <Resources/>
                </Box>
            )}
            <Footer/>
        </div>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);
