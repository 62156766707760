import {React, useEffect, useState} from 'react';
import {
    Form,
    Header,
    Input,
    Button,
    Segment,
    Dimmer,
    Loader,
    Dropdown,
    Checkbox,
    Radio,
} from 'semantic-ui-react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {LinemanPatch} from '../inputs/lineman-patch';
import {NormalPatch} from '../inputs/normal-patch';
import {Box, Textarea} from 'theme-ui';
import {
    ColorDropdown,
    SideDropdown,
    HeightDropdown,
    ToeDropdown,
    TimelineDropdown,
    StyleDropdown,
    FullSideDropdown,
    LogoDropdown,
    LiningDropdown,
    HardwareDropdown,
    OtherGoodsDropdown,
    StitchDropdown,
    SoleDropdown,
    ShankDropdown,
    PatchDropdown,
    ArchDropdown,
    WedgeDropdown,
    PrinterDropdown,
    BeltColorDropdown,
    BeltHardwareDropdown,
    ShortenedSideDropdown,
    BootModelsDropdown,

    StitchColorDropdown,
    RolledTopDropdown,
    SimpleSideDropdown,
    EHRatingDropdown,
    ArchPadDropdown,
    BlackEditionLiningDropdown,
    TopHardwareDropdown,
    TongueDropdown,
    EyeletsDropdown,
    EdgeStainColorDropdown,
    InnerShankDropdown,
    MidsoleDropdown,
    SecondarySoleDropdown,
    StitchingRowsDropdown,
    HeelPosition,
    LiftLeftFootDropdown,
    LiftRightFootDropdown,
    LeftSideSheathDropdown,
    RightSideSheathDropdown, TongueRepairDropdown,
} from './dropdowns';
import {CustomUppersInput} from '../inputs/custom-uppers';
import {SizingCustom} from '../inputs/sizing';
import {SizingCrazyCustom} from '../inputs/sizing';
import {SpecialtyItems} from '../inputs/specialty-items';
import {OpenerButton} from '../buttons/openers';
import {SideSheath} from '../inputs/side-sheath';
import PropTypes from "prop-types";


export const Ticket = ({
                           infoForForm,
                           orderType,
                           setSpecFormOpen,
                           setShowInputs,
                           reprintBy,
                           credential,
                           lineItemPosition,
                       }) => {

    console.log(infoForForm);

    const [timelineHelper, setTimelineHelper] = useState(1);
    let useTimelineHelper = false;
    const [openSizeBox, setOpenSizeBox] = useState(false);
    let useOpenSizeBox = false;
    const [openSideSheathBox, setOpenSideSheathBox] = useState(false);
    let useOpenSideSheathBox = false;
    const [openCustomUppers, setOpenCustomUppers] = useState(false);
    const [openColorBox, setOpenColorBox] = useState(false);
    let useOpenColorBox = false;
    const [openColorBox2p0, setOpenColorBox2p0] = useState(false);
    let useOpenColorBox2p0 = false;
    const [name, setName] = useState(infoForForm.name + '');
    let useName = false;
    const [arch, setArch] = useState(infoForForm.arch + '');
    let useArch = false;

    const [customColor, setCustomColor] = useState(infoForForm.customColor + '');
    let useCustomColor = false;
    const [uppersSide, setUppersSide] = useState(infoForForm.uppersSideChoice + '');
    let useUppersSide = false;
    const [lowersSide, setLowersSide] = useState(infoForForm.lowersSideChoice + '');
    let useLowerSide = false;
    const [heelLift, setHeelLift] = useState(infoForForm.heelLift + '');
    let useHeelLift = false;
    const [heelShape, setHeelShape] = useState(infoForForm.heelShape + '');
    let useHeelShape = false;
    const [height, setHeight] = useState(infoForForm.height + '');
    let useHeight = false;
    const [leftCustomUppers, setLeftCustomUppers] = useState(infoForForm.leftCustomUppers + '');
    const [rightCustomUppers, setRightCustomUppers] = useState(infoForForm.rightCustomUppers + '');
    let useCustomUppers = false;
    const [lining, setLining] = useState(infoForForm.lining + '');
    let useLining = false;
    let useLining2p0 = false;
    let isCrazyCustom = false;
    const [logo, setLogo] = useState(infoForForm.logo + '');
    let useLogo = false;
    const [otherGoods, setOtherGoods] = useState(infoForForm.otherGoods + '');
    let useOtherGoods = false;
    const [patch, setPatch] = useState(infoForForm.patch + '');
    let usePatch = false;
    const [shank, setShank] = useState(infoForForm.shank + '');
    let useShank = false;
    const [sideSheath, setSideSheath] = useState(infoForForm.sideSheath + '');
    let useSideSheath = false;
    const [sideSheathText, setSideSheathText] = useState(infoForForm.sideSheathText + '');
    const [sizeLeft, setSizeLeft] = useState(infoForForm.sizeLeft + '');
    let useSizeLeft = false;
    const [sizeRight, setSizeRight] = useState(infoForForm.sizeRight + '');
    let useSizeRight = false;
    const [sole, setSole] = useState(infoForForm.sole + '');
    let useSole = false;
    const [stitch, setStitch] = useState(infoForForm.stitch + '');
    let useStitch = false;
    const [style, setStyle] = useState(infoForForm.style + '');
    let useStyle = false;
    const [timeline, setTimeline] = useState(infoForForm.timeline + '');
    let useTimeline = false;
    const [loader, setLoader] = useState(false);
    let useLoader = false;
    const [notes, setNotes] = useState(infoForForm.notes + '');
    let useNotes = false;
    const [uppersColor, setUppersColor] = useState(infoForForm.uppersColorChoice + '');
    let useUppersColor = false;
    const [lowersColor, setLowersColor] = useState(infoForForm.lowersColorChoice + '');
    let useLowersColor = false;
    const [quarters, setQuarters] = useState(infoForForm.quarters + '');
    let useQuarters = false;
    const [hardware, setHardware] = useState(infoForForm.hardware + '');
    let useHardware = false;
    const [side, setSide] = useState(infoForForm.side + '');
    let useSide = false;
    const [size, setSize] = useState(infoForForm.size + '');
    let useSize = false;
    const [toe, setToe] = useState(infoForForm.toe + '');
    let useToe = false;
    const [color, setColor] = useState(infoForForm.color + '');
    let useColor = false;
    const [last, setLast] = useState(infoForForm.last + '');
    let useLast = false;
    const [midSole, setMidSole] = useState(infoForForm.midSole + '');
    let useMidSole = false;
    const [productQtyCount, setProductQtyCount] = useState(infoForForm.productQtyCount + '');
    let useProductQuantityCount = false;
    const [liftLeftFoot, setLiftLeftFoot] = useState(infoForForm.liftLeftFoot + '');
    let useLiftLeftFoot = false;
    const [liftRightFoot, setLiftRightFoot] = useState(infoForForm.liftRightFoot + '');
    let useLiftRightFoot = false;
    const [wedge, setWedge] = useState(infoForForm.wedge + '');
    let useWedge = false;
    const [printer, setPrinter] = useState(infoForForm.printer + '');
    let usePrinter = false;
    const [tongues, setTongues] = useState(infoForForm.tongues + '');
    let useTongue = false;


    const [inventoryStatus, setInventoryStatus] = useState(infoForForm.inventoryStatus + '');
    let useInventoryStatus = false;

    const [orderNumberName, setOrderNumberName] = useState(infoForForm.orderNumberName + '');
    const [customerName, setCustomerName] = useState(infoForForm.customerName + '');
    const [theID, setId] = useState(infoForForm.id + '');
    const [date, setDate] = useState(infoForForm.date + '');
    const [productQtyCountTotal, setProductQtyCountTotal] = useState(infoForForm.productQtyCountTotal + '');

    const [eyeletRepair, setEyeletRepair] = useState(infoForForm.eyeletRepair + '');
    let useEyeletRepair = false;
    const [patchRepair, setPatchRepair] = useState(infoForForm.patchRepair + '');
    let usePatchRepair = false;
    const [otherRepairs, setOtherRepairs] = useState(infoForForm.otherRepairs + '');
    let useOtherRepairs = false;


    const [beltHardware, setBeltHardware] = useState(infoForForm.hardware + '');
    let useBeltHardware = false;
    const [beltColor, setBeltColor] = useState(infoForForm.color + '');
    let useBeltColor = false;
    const [beltSide, setBeltSide] = useState(infoForForm.side + '');
    let useBeltSide = false;
    const [beltSize, setBeltSize] = useState(infoForForm.size + '');
    let useBeltSize = false;

    const [bootModel, setBootModel] = useState('');
    let useBootModel = false;


    //crazy custom options

    const [archPad, setArchPad] = useState(infoForForm.archPads);
    let useArchPads = false;
    let useLeftSideSheath = false;
    let useRightSideSheath = false;
    const [ehRating, setEHRating] = useState(infoForForm.ehRating);
    let useEhRating = false;
    const [quarterColor, setQuarterColor] = useState(infoForForm.quarterColor);
    const [quarterSide, setQuarterSide] = useState(
        infoForForm.quarterLeatherSide
    );
    const [tongueColor, setTongueColor] = useState(infoForForm.tongueColor);
    const [tongueSide, setTongueSide] = useState(infoForForm.tongueLeatherSide);
    const [falseTongueColor, setFalseTongueColor] = useState(
        infoForForm.falseTongueColor
    );
    const [falseTongueSide, setFalseTongueSide] = useState(
        infoForForm.falseTongueLeatherSide
    );
    const [vampColor, setVampColor] = useState(infoForForm.vampColor);
    let useVampColor = false;
    const [vampSide, setVampSide] = useState(infoForForm.vampLeatherSide);
    const [counterColor, setCounterColor] = useState(infoForForm.counterColor);
    const [counterSide, setCounterSide] = useState(
        infoForForm.counterLeatherSide
    );
    const [backstayColor, setBackstayColor] = useState(infoForForm.backstayColor);
    const [backstaySide, setBackstaySide] = useState(
        infoForForm.backstayLeatherSide
    );
    const [pullLoopColor, setPullLoopColor] = useState(infoForForm.pullLoopColor);
    const [pullLoopSide, setPullLoopSide] = useState(
        infoForForm.pullLoopLeatherSide
    );
    const [upperStitchColor, setUpperStitchColor] = useState(
        infoForForm.upperStitchColor
    );
    const [rolledTop, setRolledTop] = useState(infoForForm.rolledTop);
    let useRolledTop = false;
    const [sideSheathLeft, setSideSheathLeft] = useState(
        infoForForm.sideSheathLeft
    );
    const [sideSheathRight, setSideSheathRight] = useState(
        infoForForm.sideSheathRight
    );
    const [sizeLengthLeft, setSizeLengthLeft] = useState(
        infoForForm.sizeLengthLeft
    );
    let useSizeLengthLeft = false;
    const [sizeLengthRight, setSizeLengthRight] = useState(
        infoForForm.sizeLengthRight
    );
    let useSizeLengthRight = false;
    const [sizeWidthLeft, setSizeWidthLeft] = useState(infoForForm.sizeWidthLeft);
    let useSizeWidthLeft = false;
    const [sizeWidthRight, setSizeWidthRight] = useState(
        infoForForm.sizeWidthRight
    );
    let useSizeWidthRight = false
    const [topHardware, setTopHardware] = useState(infoForForm.topHardware);
    let useTopHardware = 0;
    const [tongue, setTongue] = useState(infoForForm.tongue);
    let useTongue2p0 = false;
    const [eyelets, setEyelets] = useState(infoForForm.numberOfEyelets);
    let useEyelets = false;
    const [edgeStain, setEdgeStain] = useState(infoForForm.edgeStain);
    let useEdgeStain = false;
    const [innerShank, setInnerShank] = useState(infoForForm.innerShank);
    let useInnerShank = false;
    const [secondarySole, setSecondarySole] = useState(infoForForm.secondarySole + '');
    let useSecondarySole = false;
    const [stitchingRows, setStitchingRows] = useState(infoForForm.stitchingRows);
    let useStitchingRows = false;
    const [heelPosition, setHeelPosition] = useState(infoForForm.heelPosition);
    let useHeelPosition = false;
    const [customMessageText, setCustomMessageText] = useState(
        infoForForm.customMessageText
    );
    let useCustomMessage = false;
    const [specialtyBoxString, setSpecialtyBoxString] = useState(
        infoForForm.specialtyFootItems
    );
    let useSpecialtyItem = false;
    const [linemanPatch, setLinemanPatch] = useState(infoForForm.linemanPatch);
    let useLinemanPatch = false;
    let useNormalPatch = false;

    //need to go though all of the crazy custom options
    const showAll = () => {
        useTimelineHelper = true;
        useOpenSizeBox = true;
        useOpenSideSheathBox = true;
        useOpenColorBox = true;
        useName = true;
        useArch = true;
        useCustomColor = true;
        useUppersSide = true;
        useLowerSide = true;
        useHeelLift = true;
        useHeelShape = true;
        useHeight = true;
        useCustomUppers = true;
        useLining = true;
        useLogo = true;
        useOtherGoods = true;
        usePatch = true;
        useShank = true;
        useSideSheath = true;
        useSizeLeft = true;
        useSizeRight = true;
        useSole = true;
        useStitch = true;
        useStyle = true;
        useTimeline = true;
        useLoader = true;
        useNotes = true;
        useUppersColor = true;
        useLowersColor = true;
        useQuarters = true;
        useHardware = true;
        useSide = true;
        useSize = true;
        useToe = true;
        useColor = true;
        useLast = true;
        useMidSole = true;
        useProductQuantityCount = true;
        useLiftLeftFoot = true;
        useLiftRightFoot = true;
        useWedge = true;
        usePrinter = true;
        useTongue = true;
        useInventoryStatus = true;
        useEyeletRepair = true;
        usePatchRepair = true;
        useOtherRepairs = true;
        useBeltHardware = true;
        useBeltColor = true;
        useBeltSide = true;
        useBeltSize = true;
        useBootModel = true;
    };

    const setUpCrazyCustom = () => {
        useVampColor = true;
        useHeight = true;
        useCustomUppers = true;
        useQuarters = true;
        useSize = true;
        useSizeLeft = true;
        useSizeRight = true;
        useLeftSideSheath = true;
        useRightSideSheath = true;
        useArch = true;
        useArchPads = true;
        useEhRating = true;
        useEdgeStain = true;
        useEyelets = true;
        useHardware = true;
        useHeelLift = true;
        useHeelShape = true;
        useLast = true;
        useHeelPosition = true;
        useLiftLeftFoot = true;
        useLiftRightFoot = true;
        useLining2p0 = true;
        useRolledTop = true;
        useLinemanPatch = true;
        useLogo = true;
        useMidSole = true;
        useOtherGoods = true;
        useNormalPatch = true;
        useInnerShank = true;
        useSecondarySole = true;
        useShank = true;
        useSide = true;
        useSole = true;
        useStitch = true;
        useStitchingRows = true;
        useStyle = true;
        useToe = true;
        useTongue2p0 = true;
        useTopHardware = true;
        useWedge = true;
        useCustomMessage = true;
        useSpecialtyItem = true;
        useSizeLengthLeft = true;
        useSizeLengthRight = true;
        useSizeWidthLeft = true;
        useSizeWidthRight = true;
        isCrazyCustom = true;
    };

    const setUpCustom = () => {

        useTimelineHelper = true;
        useOpenSizeBox = true;
        useOpenSideSheathBox = true;
        useOpenColorBox = true;
        useName = true;
        useArch = true;

        useCustomColor = true;
        useUppersSide = true;
        useLowerSide = true;
        useHeelLift = true;
        useHeelShape = true;
        useHeight = true;
        useCustomUppers = true;
        useLining = true;
        useLogo = true;
        useOtherGoods = true;
        usePatch = true;
        useShank = true;
        useSideSheath = true;
        useSizeLeft = true;
        useSizeRight = true;
        useSole = true;
        useStitch = true;
        useStyle = true;
        useTimeline = true;
        useLoader = true;
        useNotes = true;
        useUppersColor = true;
        useLowersColor = true;
        useQuarters = true;
        useHardware = true;
        useSide = true;
        useSize = true;
        useToe = true;
        useColor = true;
        useLast = true;
        useMidSole = true;
        useProductQuantityCount = true;
        useLiftLeftFoot = true;
        useLiftRightFoot = true;
        useWedge = true;
        usePrinter = true;
    };

    const setUpStock = () => {
        useSize = true;
        useNotes = true;
        usePrinter = true;
    };

    const setUpBelt = () => {
        useBeltHardware = true;
        useBeltColor = true;
        useBeltSide = true;
        useBeltSize = true;
    };

    const setUpReheel = () => {
        useHeelLift = true;
        usePrinter = true;
        useNotes = true;
    };

    const setUpResole = () => {
        useShank = true;
        useSole = true;
        useHeelLift = true;
        useHeelShape = true;
        useWedge = true;
        usePrinter = true;
        useNotes = true;
    };

    const setUpRebuild = () => {
        useSide = true;
        useColor = true;
        useStyle = true;
        useHardware = true;
        useLast = true;
        useStitch = true;
        useLining = true;
        usePatch = true;
        useToe = true;
        useTongue = true;
        useArch = true;
        useShank = true;
        useMidSole = true;
        useSole = true;
        useHeelLift = true;
        useHeelShape = true;
        useWedge = true;
        usePrinter = true;
        useSize = true;
        useSizeLeft = true;
        useSizeRight = true;
        useNotes = true;
        useTimeline = true;
    };

    const setUpGeneral = () => {
        useEyeletRepair = true;
        usePatchRepair = true;
        useOtherRepairs = true;
        useNotes = true;
        usePrinter = true;
        useTimeline = true;

    };

    const setUpTryon = () => {
        useBootModel = true;
        useSize = true;
        useSizeLeft = true;
        useSizeRight = true;
        useNotes = true;
        usePrinter = true;
        useTimeline = true;
    };

    const setUpWarranty = () => {
        useBootModel = true;
        useColor = true;
        useSize = true;
        useSizeLeft = true;
        useSizeRight = true;
        useNotes = true;
        usePrinter = true;
        useTimeline = true;
    };

    const setUpLost = () => {
        useBootModel = true;
        useColor = true;
        useSize = true;
        useNotes = true;
        usePrinter = true;
        useSole = true;
        useSide = true;
        useHeight = true;
    }


    switch (orderType) {
        case "Custom":
            setUpCustom();
            break;
        case "Stock":
            setUpStock();
            break;
        case "Belt":
            setUpBelt();
            break;
        case "Reheel":
            setUpReheel();
            break;
        case "Resole":
            setUpResole();
            break;
        case "Rebuild":
            setUpRebuild();
            break;
        case "General":
            setUpGeneral();
            break;
        case "Tryon":
            setUpTryon();
            break;
        case "Warranty":
            setUpWarranty();
            break;
        case "CrazyCustom":
            setUpCrazyCustom();
            break;
        case "LostTicket":
            setUpLost();
            break;
    }


    const toggleExpedited = (numValue) => {
        setTimelineHelper(numValue);
        if (numValue == 1) {
            setTimeline('Regular');
        } else if (numValue == 2) {
            setTimeline('Expedited');
        } else {
            setTimeline('Slow');
        }
    };

    const getTicketVersionNumber = async (oN, lIP, qP) => {
        let proxyObj = {
            url: process.env.REACT_APP_Ticket_Version_Checker_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Ticket_Version_Checker_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                orderName: oN,
                lineItemPosition: lIP,
                qtyPosition: qP,
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        var response = await buildTicketResponse.json();
        return Number(response);
    };

    const sendToPrinter = async () => {

        switch (orderType) {
            case "Custom":
                sendCustomToPrinter();
                break;
            case "Stock":
                sendStockToPrinter();
                break;
            case "Belt":
                sendBeltToPrinter();
                break;
            case "Reheel":
                sendReheelToPrinter();
                break;
            case "Resole":
                sendResoleToPrinter();
                break;
            case "Rebuild":
                sendRebuildToPrinter();
                break;
            case "General":
                sendGeneralToPrinter();
                break;
            case "Tryon":
                sendTryonToPrinter();
                break;
            case "Warranty":
                sendWarrantyToPrinter();
                break;
            case "CrazyCustom":
                sendCrazyCustomToPrinter();
                break;
            case "LostTicket":
                sendLostToPrinter();
                break;

        }
    };

    const sendCustomToPrinter = async () => {

        setLoader(true);

        console.log("why: ", infoForForm);

        var tvN = await getTicketVersionNumber(
            infoForForm.orderNumberName,
            Number(infoForForm.lineItemPosition),
            Number(productQtyCount)
        );
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: arch + '',
                color: color + '',
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                inventoryStatus: 'Custom',
                labelCount: 1,
                last: last + '',
                leftCustomUppers: leftCustomUppers + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: infoForForm.lineItemPosition,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                patch: patch + '',
                productId: infoForForm.productId + '',
                productQtyCount: Number(infoForForm.productQtyCount),
                productQtyCountTotal: infoForForm.productQtyCountTotal,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                rightCustomUppers: rightCustomUppers + '',
                shank: shank + '',
                side: side + '',
                sideSheath: sideSheath + '',
                sideSheathText: sideSheathText + '',
                size: size + '',
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: tvN,
                totalLineItems: infoForForm.totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                type: 'Custom',
                wedge: wedge + '',
                printer: printer + '',
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the Custom Printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };


    const sendStockToPrinter = async () => {
        setLoader(true);
        var tvN = await getTicketVersionNumber(
            infoForForm.orderNumberName,
            Number(infoForForm.lineItemPosition),
            Number(infoForForm.productQtyCount)
        );
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: arch + '',
                color: color + '',
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                inventoryStatus: inventoryStatus,
                labelCount: 1,
                last: last + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: infoForForm.lineItemPosition,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                patch: patch + '',
                productId: infoForForm.productId + '',
                productQtyCount: Number(infoForForm.productQtyCount),
                productQtyCountTotal: infoForForm.productQtyCountTotal,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                shank: shank + '',
                side: side + '',
                sideSheath: sideSheath + '',
                sideSheathText: sideSheathText + '',
                size: size + '',
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: tvN,
                totalLineItems: infoForForm.totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                type: 'Stock',
                printer: printer + '',
            },
        };
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the Stock Printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const sendBeltToPrinter = async () => {
        setLoader(true)
        var tvN = await getTicketVersionNumber(
            infoForForm.orderNumberName,
            Number(infoForForm.lineItemPosition),
            Number(productQtyCount)
        );
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                color: beltColor + '',//////////////////////////
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: beltHardware + '',
                id: infoForForm.id + '',
                inventoryStatus: 'Belt',
                labelCount: 1,
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: lineItemPosition,
                name: name + '',
                notes: notes,
                orderNumberName: infoForForm.orderNumberName + '',
                productQtyCount: Number(productQtyCount),
                productQtyCountTotal: infoForForm.productQtyCountTotal,
                side: beltSide + '',
                size: beltSize,
                ticketVersionNumber: tvN,
                timeline: timeline + '',
                totalLineItems: infoForForm.totalLineItems,
                type: 'Belt',
                reprintBy: reprintBy,
                printer: printer + '',
            },
        };

        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setLoader(false)
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the blue ticket printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            setLoader(false)
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const sendReheelToPrinter = async () => {
        setLoader(true);
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: arch + '',
                color: color + '',
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                inventoryStatus: 'Reheel',
                labelCount: 1,
                last: last + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: lineItemPosition,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                patch: patch + '',
                productQtyCount: 1,
                productQtyCountTotal: 1,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                shank: shank + '',
                side: side + '',
                sideSheath: sideSheath + '',
                sideSheathText: sideSheathText + '',
                size: size + '',
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: 1,
                //totalLineItems: totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                tongues: tongues + '',
                type: 'Reheel',
                wedge: wedge + '',
                printer: printer + '',
            },
        };
        //console.log("omnoomnoomnomno")
        //console.log(proxyObj.body);
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const sendResoleToPrinter = async () => {

        setLoader(true);
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: arch + '',
                color: color + '',
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                inventoryStatus: 'Resole',
                labelCount: 1,
                last: last + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: lineItemPosition,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                patch: patch + '',
                productQtyCount: 1,
                productQtyCountTotal: 1,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                shank: shank + '',
                side: side + '',
                sideSheath: sideSheath + '',
                sideSheathText: sideSheathText + '',
                size: size + '',
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: 1,
                //totalLineItems: totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                tongues: tongues + '',
                type: 'Resole',
                wedge: wedge + '',
                printer: printer + '',
            },
        };
        //console.log("omnoomnoomnomno")
        //console.log(proxyObj.body);
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const sendRebuildToPrinter = async () => {
        setLoader(true);
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: arch + '',
                color: color + '',
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                inventoryStatus: 'Rebuild',
                labelCount: 1,
                last: last + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: lineItemPosition,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                patch: patch + '',
                productQtyCount: 1,
                productQtyCountTotal: 1,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                shank: shank + '',
                side: side + '',
                sideSheath: sideSheath + '',
                sideSheathText: sideSheathText + '',
                size: size + '',
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: 1,
                //totalLineItems: totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                tongues: tongues + '',
                type: 'Rebuild',
                wedge: wedge + '',
                printer: printer + '',

            },
        };
        //console.log("omnoomnoomnomno")
        //console.log(proxyObj.body);
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const sendGeneralToPrinter = async () => {
        setLoader(true);
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: arch + '',
                color: color + '',
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                inventoryStatus: 'General',
                eyeletRepair: eyeletRepair + '',
                labelCount: 1,
                last: last + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: lineItemPosition,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                otherRepairs: otherRepairs + '',
                patch: patch + '',
                patchRepair: patchRepair + '',
                productQtyCount: 1,
                productQtyCountTotal: 1,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                shank: shank + '',
                side: side + '',
                sideSheath: sideSheath + '',
                sideSheathText: sideSheathText + '',
                size: size + '',
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: 1,
                //totalLineItems: totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                tongues: tongues + '',
                type: 'General',
                wedge: wedge + '',
                printer: printer + '',
            },
        };
        //console.log("omnoomnoomnomno")
        //console.log(proxyObj.body);
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const sendWarrantyToPrinter = async () => {
        setLoader(true);
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: arch + '',
                color: color + '',
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                inventoryStatus: 'Warranty',
                eyeletRepair: eyeletRepair + '',
                labelCount: 1,
                last: last + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: lineItemPosition,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                otherRepair: otherRepairs + '',
                patch: patch + '',
                patchRepair: patchRepair + '',
                productQtyCount: 1,
                productQtyCountTotal: 1,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                shank: shank + '',
                side: side + '',
                sideSheath: sideSheath + '',
                sideSheathText: sideSheathText + '',
                size: size + '',
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: 1,
                //totalLineItems: totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                tongues: tongues + '',
                type: 'Warranty',
                wedge: wedge + '',
                printer: printer + '',
            },
        };
        //console.log("omnoomnoomnomno");
        //console.log(proxyObj.body);
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const sendLostToPrinter = async () => {
        setLoader(true);

        let makeSizeSmall = (size) => {
            let sizeSplit = size.split('|');
            let smallSize = size + '';
            if (sizeSplit.length === 2) {
                let length;
                let width;
                let lengthSplit = sizeSplit[0].split(':');
                if (lengthSplit.length === 2) {
                    length = lengthSplit[1].trim();
                    let widthSplit = sizeSplit[1].split(':');
                    if (widthSplit.length === 2) {
                        width = widthSplit[1].trim();
                        return length + '/' + width;
                    }
                }
            }
            return size;
        }

        let smallSize;
        if (size !== '') {
            smallSize = makeSizeSmall(size);
        } else if (sizeLeft !== '' && sizeRight !== '') {
            smallSize = 'L' + makeSizeSmall(sizeLeft) + 'r' + makeSizeSmall(sizeRight);
        }

        console.log("lost ticket info check", infoForForm)

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true, //ignore //This is essentially prefilled, as in it will use the values from the form, but it needs to go through the build ticket process. In build ticket the scans will get populated in the ticket.
                arch: arch + '',
                color: color + '',
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                inventoryStatus: 'LostTicket',
                eyeletRepair: eyeletRepair + '',
                labelCount: 1,
                last: last + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: lineItemPosition,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                otherRepair: otherRepairs + '',
                patch: patch + '',
                patchRepair: patchRepair + '',
                productQtyCount: 1,
                productQtyCountTotal: 1,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                shank: shank + '',
                side: side + '',
                sideSheath: sideSheath + '',
                sideSheathText: sideSheathText + '',
                size: smallSize + '', // see above in this function
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: 1,
                //totalLineItems: totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                tongues: tongues + '',
                type: 'LostTicket',
                wedge: wedge + '',
                printer: printer + '',
                scans: infoForForm.scans,
                model: infoForForm.model + '',
                productType: infoForForm.productType + '',
            },
        };
        console.log("lost ticket print body");
        console.log(proxyObj.body);
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the red ticket printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const sendTryonToPrinter = async () => {
        setLoader(true);


        if (name === '') return;


        let proxyObjTryon = {
            url: process.env.REACT_APP_Get_Product_Specs_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Get_Product_Specs_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                color: "Black",
                name: name + '',
                searchType: 'stock',
            },
        };
        let httpResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObjTryon),
            }
        );
        let response = await httpResponse.json();
        console.log("response", response);


        ////////////////
        //console.log("lr," , sizeLeft, "," , sizeRight)
        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: response.arch + '',
                color: response.color + '',
                customColor: response.customColor + '',
                customerName: infoForForm.customerName + '',
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                hardware: response.hardware + '',
                heelLift: response.heelLift + '',
                heelShape: response.heelShape + '',
                height: response.height + '',
                id: infoForForm.id + '',
                inventoryStatus: response.inventoryStatus,
                labelCount: 1,
                last: response.last + '',
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: lineItemPosition,
                lining: response.lining + '',
                logo: response.logo + '',
                midSole: response.midSole + '',
                name: name + '',
                notes: notes + '',
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: response.otherGoods + '',
                patch: response.patch + '',
                productId: infoForForm.productId + '',
                productQtyCount: Number(infoForForm.productQtyCount),
                productQtyCountTotal: infoForForm.productQtyCountTotal,
                quarters: response.quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                shank: response.shank + '',
                side: response.side + '',
                sideSheath: response.sideSheath + '',
                sideSheathText: response.sideSheathText + '',
                size: size + '',
                sizeLeft: sizeLeft + '',
                sizeRight: sizeRight + '',
                sole: response.sole + '',
                stitch: response.stitch + '',
                style: response.style + '',
                ticketVersionNumber: 1,
                totalLineItems: infoForForm.totalLineItems,
                timeline: timeline + '',
                toe: response.toe + '',
                type: 'Tryon',
                printer: printer + '',
            },
        };
        //console.log("omnoomnoomnomno");
        //console.log(proxyObj.body);
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const checkIfMessageExists = () => {
        if (customMessageText != '') {
            return 'Yes';
        } else {
            return 'No';
        }
    };

    const sendCrazyCustomToPrinter = async () => {
        setLoader(true);

        var tvN = await getTicketVersionNumber(
            infoForForm.orderNumberName,
            Number(infoForForm.lineItemPosition),
            Number(productQtyCount),
            credential,
        );

        var customIfThere = checkIfMessageExists();

        let proxyObj = {
            url: process.env.REACT_APP_Build_Ticket_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Build_Ticket_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                prefilled: true,
                arch: arch + '',
                archPads: archPad,
                backstayColor: backstayColor,
                backstayLeatherSide: backstaySide,
                counterColor: counterColor,
                counterLeatherSide: counterSide,
                customMessage: customIfThere,
                customMessageText: customMessageText,
                customColor: customColor + '',
                customerName: infoForForm.customerName + '',
                edgeStain: edgeStain,
                date: infoForForm.date + '',
                completionDate: infoForForm.completionDate ? infoForForm.completionDate + '' : '0001-01-01T00:00:00Z',
                ehRating: ehRating,
                falseTongueColor: falseTongueColor,
                falseTongueLeatherSide: falseTongueSide,
                filmPackage: infoForForm.filmPackage,
                hardware: hardware + '',
                heelLift: heelLift + '',
                heelPosition: heelPosition,
                heelShape: heelShape + '',
                height: height + '',
                id: infoForForm.id + '',
                innerShank: innerShank,
                inventoryStatus: 'Crazy-Custom',
                labelCount: 1,
                last: last + '',
                leftCustomUppers: leftCustomUppers + '',
                liftLeftFoot: liftLeftFoot,
                liftRightFoot: liftRightFoot,
                lineItemId: infoForForm.lineItemId + '',
                lineItemPosition: infoForForm.lineItemPosition,
                linemanPatch: linemanPatch,
                lining: lining + '',
                logo: logo + '',
                midSole: midSole + '',
                name: name + '',
                notes: notes + '',
                numberOfEyelets: eyelets,
                orderNumberName: infoForForm.orderNumberName + '',
                otherGoods: otherGoods + '',
                patch: patch + '',
                productId: infoForForm.productId + '',
                productQtyCount: Number(infoForForm.productQtyCount),
                productQtyCountTotal: infoForForm.productQtyCountTotal,
                pullLoopColor: pullLoopColor,
                pullLoopLeatherSide: pullLoopSide,
                quarterColor: quarterColor,
                quarterLeatherSide: quarterSide,
                rolledTop: rolledTop,
                quarters: quarters + '',
                reprint: true,
                reprintBy: reprintBy,
                rightCustomUppers: rightCustomUppers + '',
                secondarySole: secondarySole,
                sideSheathLeft: sideSheathLeft,
                sideSheathRight: sideSheathRight,
                shank: shank + '',
                side: side + '',
                size: size + '',
                sizeLengthLeft: sizeLengthLeft + '',
                sizeWidthLeft: sizeWidthLeft + '',
                sizeLengthRight: sizeLengthRight + '',
                sizeWidthRight: sizeWidthRight + '',
                specialtyFootItems: specialtyBoxString,
                sole: sole + '',
                stitch: stitch + '',
                style: style + '',
                ticketVersionNumber: tvN,
                totalLineItems: infoForForm.totalLineItems,
                timeline: timeline + '',
                toe: toe + '',
                tongue: tongue,
                tongueColor: tongueColor,
                tongueLeatherSide: tongueSide,
                topHardware: topHardware,
                upperStitchColor: upperStitchColor,
                uppersSideChoice: uppersSide,
                vampColor: vampColor,
                vampLeatherSide: vampSide,
                type: 'Crazy-Custom',
            },
        };
        let buildTicketResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        if (buildTicketResponse.status < 300 && buildTicketResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the rebuild printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            //addTag(orderNumber);
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    }

    const editWedge = () => {
        setWedge(
            'UPPERS: Color: ' +
            uppersColor +
            ', Side: ' +
            uppersSide +
            ' | LOWERS: Color: ' +
            lowersColor +
            ', Side: ' +
            lowersSide
        );
    };

    const editCustomColor = () => {
        setCustomColor(
            'UPPERS: Color: ' +
            uppersColor +
            ', Side: ' +
            uppersSide +
            ' | LOWERS: Color: ' +
            lowersColor +
            ', Side: ' +
            lowersSide
        );
        setColor('See notes field');
        setSide('See notes field');
        setOpenColorBox(false);
    };

    const setColorBox = (colorValue) => {
        setColor(colorValue);
        setCustomColor('');
        setUppersSide('');
        setLowersSide('');
        setUppersColor('');
        setLowersColor('');
        setOpenColorBox(false);
    };

    const setSideBox = (sideValue) => {
        setSide(sideValue);
        setCustomColor('');
        setUppersSide('');
        setLowersSide('');
        setUppersColor('');
        setLowersColor('');
        setOpenColorBox(false);
    };


    return (
        <Form onSubmit={() => {
            if (window.navigator.platform.toLowerCase().includes("mac") ) {
                sendToPrinter()
            }
        }}>
            <Button type={'submit'} disabled={true} style={{display: "none"}} hidden></Button>
            <Dimmer.Dimmable as={Segment} dimmed={loader} basic>
                <Dimmer active={loader} inverted>
                    <Loader>Loading</Loader>
                </Dimmer>

                <Header as='h1'>{name}</Header>
                {useBootModel ? (
                    <Form.Field>
                        <Header>Boot Model</Header>
                        <BootModelsDropdown bootModel={name} setBootModel={setName}/>
                    </Form.Field>
                ) : null}
                {useBeltHardware ? (
                    <Form.Field>
                        <Header>Hardware</Header>
                        <BeltHardwareDropdown hardware={beltHardware} setHardware={setBeltHardware}/>
                    </Form.Field>) : null}
                {useBeltColor ? (
                    <Form.Field>
                        <Header>Color</Header>
                        <BeltColorDropdown color={beltColor} setColor={setBeltColor}/>
                    </Form.Field>) : null}
                {useBeltSide ? (
                    <Form.Field>
                        <Header>Side</Header>
                        <ShortenedSideDropdown side={beltSide} setSide={setBeltSide}/>
                    </Form.Field>) : null}
                {useBeltSize ? (
                    <Form.Field>
                        <Header>Size</Header>
                        <Input
                            placeholder='Enter the size'
                            value={beltSize}
                            onChange={(_, {value}) => setBeltSize(value)}
                        />
                    </Form.Field>) : null}


                <Box sx={{mt: 20, mb: 20}}>

                    {useVampColor ? (
                        <OpenerButton
                            text={'Edit Custom Color'}
                            setOpener={setOpenColorBox2p0}
                        />) : null}
                    {openColorBox2p0 ? (
                        <Segment>
                            <Form.Field>
                                <Header>Quarter Color:</Header>
                                <ColorDropdown
                                    color={quarterColor}
                                    setColor={setQuarterColor}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Quarter Side:</Header>
                                <SimpleSideDropdown
                                    side={quarterSide}
                                    setSide={setQuarterSide}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Tongue Color:</Header>
                                <ColorDropdown color={tongueColor} setColor={setTongueColor}/>
                            </Form.Field>
                            <Form.Field>
                                <Header>Tongue Side:</Header>
                                <SimpleSideDropdown side={tongueSide} setSide={setTongueSide}/>
                            </Form.Field>
                            <Form.Field>
                                <Header>False Tongue Color:</Header>
                                <ColorDropdown
                                    color={falseTongueColor}
                                    setColor={setFalseTongueColor}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>False Tongue Side:</Header>
                                <SimpleSideDropdown
                                    side={falseTongueSide}
                                    setSide={setFalseTongueSide}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Vamp Color:</Header>
                                <ColorDropdown color={vampColor} setColor={setVampColor}/>
                            </Form.Field>
                            <Form.Field>
                                <Header>Vamp Side:</Header>
                                <SimpleSideDropdown side={vampSide} setSide={setVampSide}/>
                            </Form.Field>
                            <Form.Field>
                                <Header>Counter Color:</Header>
                                <ColorDropdown
                                    color={counterColor}
                                    setColor={setCounterColor}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Counter Side:</Header>
                                <SimpleSideDropdown
                                    side={counterSide}
                                    setSide={setCounterSide}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Backstay Color:</Header>
                                <ColorDropdown
                                    color={backstayColor}
                                    setColor={setBackstayColor}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Backstay Side:</Header>
                                <SimpleSideDropdown
                                    side={backstaySide}
                                    setSide={setBackstaySide}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Pull Loop Color:</Header>
                                <ColorDropdown
                                    color={pullLoopColor}
                                    setColor={setPullLoopColor}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Pull Loop Side:</Header>
                                <SimpleSideDropdown
                                    side={pullLoopSide}
                                    setSide={setPullLoopSide}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header>Upper Stitch Color:</Header>
                                <StitchColorDropdown
                                    color={upperStitchColor}
                                    setColor={setUpperStitchColor}
                                />
                            </Form.Field>
                            <Button
                                type='button'
                                color='green'
                                onClick={() => editCustomColor()}
                            >
                                Update the custom color
                            </Button>
                            <Button
                                type='button'
                                color='red'
                                onClick={() => setOpenColorBox2p0(false)}
                            >
                                Close
                            </Button>
                        </Segment>
                    ) : null}
                    {useVampColor ? (
                        <Header>Custom Color and Side List:</Header>
                    ) : null}


                    {useVampColor ? (
                        <Segment style={{margin: '0 auto'}}>

                            {useVampColor ? (
                                <Box>
                                    <b>Quarter Color: </b>
                                    {quarterColor} | Side: {quarterSide}
                                </Box>) : null}
                            {useVampColor ? (
                                <Box>
                                    <b>Tongue Color: </b>
                                    {tongueColor} | Side: {tongueSide}
                                </Box>) : null}
                            {useVampColor ? (
                                <Box>
                                    <b>False Tongue Color: </b>
                                    {falseTongueColor} | Side: {falseTongueSide}
                                </Box>) : null}
                            {useVampColor ? (
                                <Box>
                                    <b>Vamp Color: </b>
                                    {vampColor} | Side: {vampSide}
                                </Box>) : null}
                            {useVampColor ? (
                                <Box>
                                    <b>Counter Color: </b>
                                    {counterColor} | Side: {counterSide}
                                </Box>) : null}
                            {useVampColor ? (
                                <Box>
                                    <b>Backstay Color: </b>
                                    {backstayColor} | Side: {backstaySide}
                                </Box>) : null}
                            {useVampColor ? (
                                <Box>
                                    <b>Pull-Loop Color: </b>
                                    {pullLoopColor} | Side: {pullLoopSide}
                                </Box>) : null}
                            {useVampColor ? (
                                <Box>
                                    <b>Upper Stitch Color: </b>
                                    {upperStitchColor}
                                </Box>) : null}
                        </Segment>) : null}
                </Box>


                <Box sx={{mt: 20, mb: 20}}>
                    {useColor ? (
                        <OpenerButton
                            text={'Edit Custom Color'}
                            setOpener={setOpenColorBox}
                        />) : null}
                    {openColorBox ? (
                        <Segment>
                            <Form.Field>
                                <Header>Uppers Color</Header>
                                <ColorDropdown color={uppersColor} setColor={setUppersColor}/>
                            </Form.Field>
                            <Form.Field>
                                <Header>Uppers Side</Header>
                                <SideDropdown side={uppersSide} setSide={setUppersSide}/>
                            </Form.Field>
                            <Form.Field>
                                <Header>Lowers Color</Header>
                                <ColorDropdown color={lowersColor} setColor={setLowersColor}/>
                            </Form.Field>
                            <Form.Field>
                                <Header>Lowers Side</Header>
                                <SideDropdown side={lowersSide} setSide={setLowersSide}/>
                            </Form.Field>
                            <Button
                                type='button'
                                color='green'
                                onClick={() => editCustomColor()}
                            >
                                Update the custom color
                            </Button>
                            <Button
                                type='button'
                                color='red'
                                onClick={() => setOpenColorBox(false)}
                            >
                                Close
                            </Button>
                        </Segment>) : null}
                    {useColor ? (
                        <Form.Field>
                            <Header>Color</Header>
                            <Input
                                placeholder='Set color'
                                value={color}
                                onChange={(_, {value}) => setColorBox(value)}
                            />
                        </Form.Field>) : null}
                    {useCustomColor ? (
                        <Header>Custom Color </Header>
                    ) : null}
                    {useCustomColor ? (
                        <Segment style={{margin: '0 auto'}}>
                            {customColor}
                        </Segment>) : null}
                </Box>


                {useHeight ? (
                    <Form.Field>
                        <Header>Height</Header>
                        <HeightDropdown height={height} setHeight={setHeight}/>
                    </Form.Field>
                ) : null}

                <Box sx={{mt: 20, mb: 20}}>
                    {useCustomUppers ? (
                        <OpenerButton
                            text={'Edit Custom Uppers'}
                            setOpener={setOpenCustomUppers}
                        />) : null}
                    {openCustomUppers ? (
                        <Segment basic>
                            <CustomUppersInput
                                height={height}
                                setLeftCustomUppers={setLeftCustomUppers}
                                setRightCustomUppers={setRightCustomUppers}
                                setQuarters={setQuarters}
                                setOpenCustomUppers={setOpenCustomUppers}
                            />
                        </Segment>
                    ) : null}
                    {useCustomUppers ? (
                        <Header style={{marginTop: 0}}>Left Custom Upper</Header>) : null}
                    {useCustomUppers ? (
                        <Segment style={{margin: '0 auto'}}>
                            {leftCustomUppers}
                        </Segment>) : null}
                    {useCustomUppers ? (
                        <Header style={{marginTop: 15}}>Right Custom Upper</Header>) : null}
                    {useCustomUppers ? (
                        <Segment style={{margin: '0 auto'}}>
                            {rightCustomUppers}
                        </Segment>) : null}
                </Box>

                <Box sx={{mt: 2, mb: 20}}>
                    {useSize ? (
                        <OpenerButton text={'Edit Sizing'} setOpener={setOpenSizeBox}/>) : null}
                    {openSizeBox && !isCrazyCustom ?   (
                        <SizingCustom
                            size={size}
                            setSize={setSize}
                            setSizeLeft={setSizeLeft}
                            setSizeRight={setSizeRight}
                            setOpenSizeBox={setOpenSizeBox}
                        />
                    ) : null}

                    {openSizeBox && isCrazyCustom ?   (
                        <SizingCrazyCustom
                        setSizeLengthLeft={setSizeLengthLeft}
                        setSizeLengthRight={setSizeLengthRight}
                        setSizeWidthLeft={setSizeWidthLeft}
                        setSizeWidthRight={setSizeWidthRight}
                        setOpenSizeBox={setOpenSizeBox}
                        />
                    ) : null}

                    
                    {useSize ? (
                        <Header style={{marginTop: 0}}>Size (if boots are same):</Header>) : null}
                    {useSize ? (
                        <Segment style={{margin: '0 auto'}}>{size}</Segment>) : null}
                    {useSizeLeft ? (
                        <Header style={{marginTop: 15}}>Size (Size Left):</Header>) : null}
                    {useSizeLeft ? (
                        <Segment style={{margin: '0 auto'}}>{sizeLeft}</Segment>) : null}
                    {useSizeRight ? (
                        <Header style={{marginTop: 15}}>Size (Size Right):</Header>) : null}
                    {useSizeRight ? (
                        <Segment style={{margin: '0 auto'}}> {sizeRight}</Segment>) : null}
                    {useSizeLengthLeft ? (
                        <Header style={{marginTop:15}}>Size (Length Left):</Header>) : null}
                    {useSizeLengthLeft ? (
                        <Segment style={{margin: '0 auto'}}> {sizeLengthLeft}</Segment>) : null}
                    {useSizeLengthRight ? (
                        <Header style={{marginTop:15}}>Size (Length Right):</Header>) : null}
                    {useSizeLengthRight ? (
                        <Segment style={{margin: '0 auto'}}> {sizeLengthRight}</Segment>) : null}
                    {useSizeWidthLeft ? (
                        <Header style={{marginTop:15}}>Size (Width Left):</Header>) : null}
                    {useSizeWidthLeft ? (
                        <Segment style={{margin: '0 auto'}}> {sizeWidthLeft}</Segment>) : null}
                    {useSizeWidthRight ? (
                        <Header style={{marginTop:15}}>Size (Width Right):</Header>) : null}
                    {useSizeWidthRight ? (
                        <Segment style={{margin: '0 auto'}}> {sizeWidthRight}</Segment>) : null}
                    <Box style={{marginTop: 20}}>
                        {useQuarters ? (
                            <Form.Field>
                                <Header>Quarters</Header>
                                <Input
                                    placeholder='Set quarters'
                                    value={quarters}
                                    onChange={(_, {value}) => setQuarters(value)}
                                />
                            </Form.Field>) : null}
                    </Box>
                </Box>

                <Box sx={{mt: 2, mb: 20}}>
                    {useLeftSideSheath ? (
                        <Header style={{marginTop: 0}}>Left Side Sheath:</Header>) : null}
                    {useLeftSideSheath ? (
                        <LeftSideSheathDropdown
                            dropdownText={sideSheathLeft}
                            setDropdownSideSheathText={setSideSheathLeft}
                        />
                    ) : null}
                    <br/>
                    {useRightSideSheath ? (
                        <Header style={{marginTop: 0}}>Right Side Sheath:</Header>
                    ) : null}
                    {useRightSideSheath ? (
                        <RightSideSheathDropdown
                            dropdownText={sideSheathRight}
                            setDropdownSideSheathText={setSideSheathRight}
                        />
                    ) : null}
                </Box>

                <Box sx={{mt: 2, mb: 20}}>
                    {useSideSheath ? (
                        <OpenerButton
                            text={'Edit Side Sheath'}
                            setOpener={setOpenSideSheathBox}
                        />) : null}
                    {openSideSheathBox ? (
                        <SideSheath
                            setSideSheath={setSideSheath}
                            setSideSheathText={setSideSheathText}
                            setOpenSideSheathBox={setOpenSideSheathBox}
                        />
                    ) : null}

                    {useSideSheath ? (
                        <Header style={{marginTop: 0}}>Side Sheath Text: </Header>) : null}
                    {useSideSheath ? (
                        <Segment style={{margin: '0 auto'}}>
                            {sideSheathText}
                        </Segment>) : null}
                </Box>
                {useArch ? (
                    <Form.Field>
                        <Header>Arch</Header>
                        <ArchDropdown arch={arch} setArch={setArch}/>
                    </Form.Field>) : null}
                {useArchPads ? (
                    <Form.Field>
                        <Header>Arch Pads</Header>
                        <ArchPadDropdown archPad={archPad} setArchPad={setArchPad}/>
                    </Form.Field>) : null}
                {useEhRating ? (
                    <Form.Field>
                        <Header>EH Rating</Header>
                        <EHRatingDropdown ehRating={ehRating} setEHRating={setEHRating}/>
                    </Form.Field>) : null}
                {useEdgeStain ? (
                    <Form.Field>
                        <Header>Edge Stain</Header>
                        <EdgeStainColorDropdown
                            edgeStain={edgeStain}
                            setEdgeStain={setEdgeStain}
                        />
                    </Form.Field>) : null}
                {useEyelets ? (
                    <Form.Field>
                        <Header>Eyelets</Header>
                        <EyeletsDropdown eyelets={eyelets} setEyelets={setEyelets}/>
                    </Form.Field>) : null}
                {useHardware ? (
                    <Form.Field>
                        <Header>Hardware</Header>
                        <HardwareDropdown hardware={hardware} setHardware={setHardware}/>
                    </Form.Field>) : null}
                {useHeelLift ? (
                    <Form.Field>
                        <Header>Heel Lift</Header>
                        <Input
                            placeholder='Set heel lift'
                            value={heelLift}
                            onChange={(_, {value}) => setHeelLift(value)}
                        />
                    </Form.Field>) : null}
                {useHeelPosition ? (
                    <Form.Field>
                        <Header>Heel Position</Header>
                        <HeelPosition
                            heelPosition={heelPosition}
                            setHeelPosition={setHeelPosition}
                        />
                    </Form.Field>) : null}
                {useHeelShape ? (
                    <Form.Field>
                        <Header>Heel Shape</Header>
                        <Input
                            placeholder='Set heel shape'
                            value={heelShape}
                            onChange={(_, {value}) => setHeelShape(value)}
                        />
                    </Form.Field>) : null}
                {useLast ? (
                    <Form.Field>
                        <Header>Lasting</Header>
                        <Input
                            placeholder='Set last'
                            value={last}
                            onChange={(_, {value}) => setLast(value)}
                        />
                    </Form.Field>) : null}
                {useLiftLeftFoot ? (
                    <Form.Field>
                        <Header>Lift Left Foot</Header>
                        <LiftLeftFootDropdown
                            liftLeftFoot={liftLeftFoot}
                            setLiftLeftFoot={setLiftLeftFoot}
                        />
                    </Form.Field>) : null}
                {useLiftRightFoot ? (
                    <Form.Field>
                        <Header>Lift Right Foot</Header>
                        <LiftRightFootDropdown
                            liftRightFoot={liftRightFoot}
                            setLiftRightFoot={setLiftRightFoot}
                        />
                    </Form.Field>) : null}
                {useLining2p0 ? (
                    <Form.Field>
                        <Header>Lining</Header>
                        <BlackEditionLiningDropdown lining={lining} setLining={setLining}/>
                    </Form.Field>) : null}
                {useLining ? (
                    <Form.Field>
                        <Header>Lining</Header>
                        <LiningDropdown lining={lining} setLining={setLining}/>
                    </Form.Field>) : null}
                {useRolledTop ? (
                    <Form.Field>
                        <Header>Rolled Top</Header>
                        <RolledTopDropdown
                            rolledTop={rolledTop}
                            setRolledTop={setRolledTop}
                        />
                    </Form.Field>) : null}

                {useLinemanPatch ? (
                    <Form.Field>
                        <Header>Lineman Patch</Header>
                        {/* <PatchDropdown patch={patch} setPatch={setPatch} /> */}
                        <LinemanPatch
                            linemanPatch={linemanPatch}
                            setLinemanPatch={setLinemanPatch}
                        />
                    </Form.Field>) : null}
                {useLinemanPatch ? (
                    <Segment style={{margin: '0 auto'}}>
                        {linemanPatch}
                    </Segment>) : null}


                {useLogo ? (
                    <Form.Field>
                        <Header>Logo</Header>
                        <LogoDropdown logo={logo} setLogo={setLogo}/>
                    </Form.Field>) : null}
                {useMidSole ? (
                    <Form.Field>
                        <Header>Midsole</Header>
                        <Input
                            placeholder='Set midsole'
                            value={midSole}
                            onChange={(_, {value}) => setMidSole(value)}
                        />
                    </Form.Field>) : null}
                {useOtherGoods ? (
                    <Form.Field>
                        <Header>Other Goods</Header>
                        <OtherGoodsDropdown
                            otherGoods={otherGoods}
                            setOtherGoods={setOtherGoods}
                        />
                    </Form.Field>) : null}
                {usePatch ? (
                    <Form.Field>
                        <Header>Patch</Header>
                        <PatchDropdown patch={patch} setPatch={setPatch}/>
                    </Form.Field>) : null}
                {useNormalPatch ? (
                    <Form.Field>
                        <Header>Patch</Header>
                        <NormalPatch patch={patch} setPatch={setPatch}/>
                    </Form.Field>) : null}
                {useNormalPatch ? (
                    <Segment style={{margin: '0 auto'}}>{patch}</Segment>) : null}
                {useInnerShank ? (
                    <Form.Field>
                        <Header>Inner Shank</Header>
                        <InnerShankDropdown
                            innerShank={innerShank}
                            setInnerShank={setInnerShank}
                        />
                    </Form.Field>) : null}
                {useSecondarySole ? (
                    <Form.Field>
                        <Header>Secondary Sole</Header>
                        <SecondarySoleDropdown
                            secondarySole={secondarySole}
                            setSecondarySole={setSecondarySole}
                        />
                    </Form.Field>) : null}
                {useShank ? (
                    <Form.Field>
                        <Header>Shank</Header>
                        <ShankDropdown shank={shank} setShank={setShank}/>
                    </Form.Field>) : null}
                {useSide ? (
                    <Form.Field>
                        <Header>Side</Header>
                        <FullSideDropdown side={side} setSide={setSideBox}/>
                    </Form.Field>) : null}
                {useSole ? (
                    <Form.Field>
                        <Header>Sole</Header>
                        <SoleDropdown sole={sole} setSole={setSole}/>
                    </Form.Field>) : null}
                {useStitch ? (
                    <Form.Field>
                        <Header>Stitch</Header>
                        <StitchDropdown stitch={stitch} setStitch={setStitch}/>
                    </Form.Field>) : null}
                {useStitchingRows ? (
                    <Form.Field>
                        <Header>Stitching Rows</Header>
                        <StitchingRowsDropdown
                            stitchingRows={stitchingRows}
                            setStitchingRows={setStitchingRows}
                        />
                    </Form.Field>) : null}
                {useStyle ? (
                    <Form.Field>
                        <Header>Style</Header>
                        <StyleDropdown style={style} setStyle={setStyle}/>
                    </Form.Field>) : null}
                {useToe ? (
                    <Form.Field>
                        <Header>Toes</Header>
                        <ToeDropdown toe={toe} setToe={setToe}/>
                    </Form.Field>) : null}
                {useTongue2p0 ? (
                    <Form.Field>
                        <Header>Tongue</Header>
                        <TongueDropdown tongue={tongue} setTongue={setTongue}/>
                    </Form.Field>) : null}
                {useTopHardware ? (
                    <Form.Field>
                        <Header>Top Hardware</Header>
                        <TopHardwareDropdown
                            topHardware={topHardware}
                            setTopHardware={setTopHardware}
                        />
                    </Form.Field>) : null}
                {useTongue ? (
                    <Form.Field>
                        <Header>Tongue</Header>
                        <TongueRepairDropdown
                            tongues={tongues}
                            setTongues={setTongues}
                        />
                    </Form.Field>) : null}
                {useWedge ? (
                    <Form.Field>
                        <Header>Wedge</Header>
                        <WedgeDropdown wedge={wedge} setWedge={setWedge}/>
                    </Form.Field>) : null}
                {useSpecialtyItem ? (
                    <SpecialtyItems
                        specialtyBoxString={specialtyBoxString}
                        setSpecialtyBoxString={setSpecialtyBoxString}
                    />) : null}
                {useSpecialtyItem ? (
                    <Segment style={{margin: '0 auto'}}>
                        {specialtyBoxString}
                    </Segment>) : null}
                {useSpecialtyItem ? (<br/>) : null}
                {useCustomMessage ? (
                    <Form.Field>
                        <Header>Custom Message</Header>
                        <Textarea placeholder='Set Notes'
                                  value={notes}
                                  onChange={(evt) => setNotes(evt.target.value)}>

                        </Textarea>
                    </Form.Field>) : null}

                {useEyeletRepair ? (
                    <Form.Field>
                        <Header>Eyelet Repair</Header>
                        <Input
                            placeholder='Set Eyelet Repair'
                            value={eyeletRepair}
                            onChange={(_, {value}) => setEyeletRepair(value)}
                        />
                    </Form.Field>) : null}
                {usePatchRepair ? (
                    <Form.Field>
                        <Header>Patch Repair</Header>
                        <Input
                            placeholder='Set Patch Repair'
                            value={patchRepair}
                            onChange={(_, {value}) => setPatchRepair(value)}
                        />
                    </Form.Field>) : null}
                {useOtherRepairs ? (
                    <Form.Field>
                        <Header>Other Repair</Header>
                        <Input
                            placeholder='Set  Other Repair'
                            value={otherRepairs}
                            onChange={(_, {value}) => setOtherRepairs(value)}
                        />
                    </Form.Field>) : null}
                {useNotes ? (
                    <Form.Field>
                        <Header>Notes</Header>
                        <Textarea placeholder='Set Notes'
                                  value={notes}
                                  onChange={(evt) => setNotes(evt.target.value)}>

                        </Textarea>
                    </Form.Field>) : null}
                {usePrinter ? (
                    <Form.Field>
                        <Header>Printer</Header>
                        <PrinterDropdown printer={printer} setPrinter={setPrinter}/>
                    </Form.Field>) : null}
                <Box sx={{mt: 20, float: 'right', mb: 30}}>
                    {timelineHelper === 1 ? (
                        <Button
                            color='olive'
                            type='button'
                            onClick={() => toggleExpedited(2)}
                        >
                            Regular Timeline
                        </Button>
                    ) : null}
                    {timelineHelper === 2 ? (
                        <Button
                            color='olive'
                            type='button'
                            onClick={() => toggleExpedited(3)}
                        >
                            Expedited Timeline
                        </Button>
                    ) : null}
                    {timelineHelper === 3 ? (
                        <Button
                            color='olive'
                            type='button'
                            onClick={() => toggleExpedited(1)}
                        >
                            Slow Timeline
                        </Button>
                    ) : null}
                    <Box sx={{mt: 10}}>
                        <Button type='submit' color='green'>
                            Submit
                        </Button>
                    </Box>
                </Box>

            </Dimmer.Dimmable>
        </Form>
    );
};

Ticket.propTypes = {
    credential: PropTypes.string.isRequired
}
