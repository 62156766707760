import {React, useEffect, useState} from 'react';
import {Segment, Button, Dropdown, Icon} from 'semantic-ui-react';
import {Box} from 'theme-ui';
import {CustomTicket} from '../boot-model-forms/custom-ticket';
import {Ticket} from '../boot-model-forms/ticket';
import {
    BootModelsDropdown,
    ColorDropdown,
    BeltModelsDropdown,
} from '../boot-model-forms/dropdowns';
/**
 * In this function, the user selects the boot model, hits enter, and then the selection gets sent to a lambda function that takes the
 * DynamoDB item and sends it back, and then the user will have an opportunity to fill in the remaining fields
 *
 */

export const NewTicket = ({
                                    orderType,
                                    totalLineItems,
                                    setDisplayEditor,//
                                    customerName,
                                    orderNumber, 
                                    orderId,
                                    orderedDate,
                                    reprintBy,//
                                    credential,//
                                }) => {
    const [bootSelection, setBootSelection] = useState('');
    let helpInfoForForm = {
        orderId: orderId + '',
        totalLineItems: totalLineItems + '',
        orderedDate: orderedDate + '',
        orderNumber: orderNumber + '',
        customerName: customerName + '',
        arch: '',
        color: '',
        customColor: '',
        customerName: '',
        date: '',
        eyeletRepair: '',
        hardware: '',
        heelLift: '',
        heelShape: '',
        height: '',
        inventoryStatus: '',
        labelCount: '',
        last: '',
        leftCustomUppers: '',
        lining: '',
        logo: '',
        midSole: '',
        name: '',
        notes: '',
        otherGoods: '',
        otherRepairs: '',
        patch: '',
        patchRepair: '',
        productQtyCount: '',
        quarters: '',
        reprintBy: '',
        rightCustomUppers: '',
        shank: '',
        side: '',
        sideSheath: '',
        sideSheathText: '',
        size: '',
        sizeLeft: '',
        sizeRight: '',
        sole: '',
        stitch: '',
        style: '',
        tvN: '',
        totalLineItems: '',
        timeline: '',
        toe: '',
        type: '',
        wedge: '',
        printer: '',
    }//this is for the ones that don't grab inforamtion
    const [infoForForm, setInfoForForm] = useState(helpInfoForForm);
    const [specFormOpen, setSpecFormOpen] = useState(false);
    const [productSpecsNotFound, setProductSpecsNotFound] = useState(false);
    const [showInputs, setShowInputs] = useState(true);

    const [bootModel, setBootModel] = useState('');
    const [color, setColor] = useState('');

    const [belt, setBelt] = useState('');

    let primaryKey = '';

    if(orderType == "Custom")
    {
        primaryKey = 'bootModel';
    }
    if(!specFormOpen && (orderType == "Rebuild" || orderType == "Reheel" || orderType == "Resole" || orderType == "General" || orderType == "Warranty" || orderType == "Temporary" || orderType == "Tryon"))
    {
        setSpecFormOpen(true);
    }

    
    const devCustomBoots = [
        {
            key: '7509040464042',
            text: 'Fire Inlander',
            value: '7509040464042',
        },
        {
            key: '7509039153322',
            text: 'Climber',
            value: '7509039153322',
        },
        {
            key: '7509040136362',
            text: 'Arctic',
            value: '7509040136362',
        },
        {
            key: '7509025980586',
            text: 'Caulk Logger',
            value: '7509025980586',
        },
        {
            key: '7509025980586',
            text: 'Climber',
            value: '7509025980586',
        },
        {
            key: '7509040758954',
            text: 'Forefront',
            value: '7509040758954',
        },
        {
            key: '7509039513770',
            text: 'Harvester',
            value: '7509039513770',
        },
        {
            key: '7509039284394',
            text: 'Ironhide',
            value: '7509039284394',
        },
        {
            key: '7509041053866',
            text: 'O.T.',
            value: '7509041053866',
        },
    ];

    const prodCustomBoots = [
        {
            key: '7610901496006',
            text: 'Fire Inlander',
            value: '7610901496006',
        },
        {
            key: '7610957234374',
            text: 'Climber',
            value: '7610957234374',
        },
        {
            key: '7610903298246',
            text: 'Arctic',
            value: '7610903298246',
        },
        {
            key: '7509025980586',
            text: 'Climber',
            value: '7509025980586',
        },
        {
            key: '7610897072326',
            text: 'Forefront',
            value: '7610897072326',
        },
        {
            key: '7610946257094',
            text: 'Harvester',
            value: '7610946257094',
        },
        {
            key: '7610950811846',
            text: 'Ironhide',
            value: '7610950811846',
        },
        {
            key: '7610894483654',
            text: 'O.T.',
            value: '7610894483654',
        },
        {
            key: '7610383990982',
            text: 'Superduty',
            value: '7610383990982',
        },
        {
            key: '7157539963078',
            text: 'Station Boot',
            value: '7157539963078',
        },
        {
            key: '7648052478150',
            text: 'Caulk Logger',
            value: '7648052478150',
        },
        {
            key: '7610908147910',
            text: 'Western Packer',
            value: '7610908147910',
        },
    ];

    const getModelSpecsCustom = async () => {
        if (bootSelection === '') return;

        let proxyObj = {
            url: process.env.REACT_APP_Get_Product_Specs_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Get_Product_Specs_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                primaryKey: primaryKey + '',
                productId: bootSelection + '',
                searchType: 'custom',
            },
        };
        let httpResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        let response = await httpResponse.json();
        setShowInputs(false);
        helpInfoForForm = response;
        helpInfoForForm.orderId = orderId + '';
        helpInfoForForm.totalLineItems = totalLineItems + '';
        helpInfoForForm.orderedDate = orderedDate + '';
        helpInfoForForm.orderNumber = orderNumber + '';
        helpInfoForForm.customerName = customerName + '';
        helpInfoForForm.wedge = '';
        helpInfoForForm.printer =  '';
        //helpInfoForForm.lineItemPosition = 1 + '';
        setInfoForForm(helpInfoForForm);
        setSpecFormOpen(true);
    };

    const findModelFromDBStock = async () => {
        if (bootModel === '' && color === '') return;

        let proxyObj = {
            url: process.env.REACT_APP_Get_Product_Specs_Link,
            method: 'POST',
            api_key_header: "X-Api-Key",
            api_key_lookup_value: process.env.REACT_APP_Get_Product_Specs_API_Key,
            headers: [
                {
                    key: 'Content-Type',
                    value: 'application/json',
                },
                {
                    key: 'Access-Control-Allow-Methods',
                    value: '*',
                },
            ],
            body: {
                color: color + '',
                name: bootModel + '',
                searchType: 'stock',
            },
        };
        let httpResponse = await fetch(
            'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(proxyObj),
            }
        );
        let response = await httpResponse.json();
        setShowInputs(false);
        helpInfoForForm = response;
        helpInfoForForm.orderId = orderId + '';
        helpInfoForForm.totalLineItems = totalLineItems + '';
        helpInfoForForm.orderedDate = orderedDate + '';
        helpInfoForForm.orderNumber = orderNumber + '';
        helpInfoForForm.customerName = customerName + '';
        //helpInfoForForm.lineItemPosition = 1 + '';
        helpInfoForForm.printer =  '';
        setInfoForForm(helpInfoForForm);
        setSpecFormOpen(true);
    };

    const findModelFromDBBelt = async () => {
        if (belt === '' && color === '') return;
    
        let proxyObj = {
          url: process.env.REACT_APP_Get_Product_Specs_Link,
          method: 'POST',
          api_key_header: "X-Api-Key",
          api_key_lookup_value: process.env.REACT_APP_Get_Product_Specs_API_Key,
          headers: [
            {
              key: 'Content-Type',
              value: 'application/json',
            },
            {
              key: 'Access-Control-Allow-Methods',
              value: '*',
            },
          ],
          body: {
            name: belt + '',
            searchType: 'belt',
          },
        };
        let httpResponse = await fetch(
          'https://hi9tz1o8vg.execute-api.us-east-2.amazonaws.com/default/ReprintAppProxy',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': credential,
            },
            body: JSON.stringify(proxyObj),
          }
        );
        let response = await httpResponse.json();
        setShowInputs(false);
        helpInfoForForm = response;
        helpInfoForForm.orderId = orderId + '';
        helpInfoForForm.totalLineItems = totalLineItems + '';
        helpInfoForForm.orderedDate = orderedDate + '';
        helpInfoForForm.orderNumber = orderNumber + '';
        helpInfoForForm.customerName = customerName + '';
        //helpInfoForForm.lineItemPosition = 1 + '';
        helpInfoForForm.printer =  '';
        setInfoForForm(helpInfoForForm);
        setSpecFormOpen(true);
    };

    useEffect(() => {
        if (infoForForm === undefined) {
            console.log('Undefined');
            return;
        }
        infoForForm.id = orderNumber;
        infoForForm.customerName = customerName;
        infoForForm.orderNumberName = orderId;
        infoForForm.totalLineItems = totalLineItems;
        infoForForm.productQtyCount = 1;
        infoForForm.productQtyCountTotal = 1;
        infoForForm.ticketVersionNumber = 1;
        infoForForm.date = orderedDate;
        if (infoForForm.message === 'Internal server error') {
            console.log('There is an error');
            setProductSpecsNotFound(true);
        }
    }, [infoForForm]);

    return (
        <Segment basic>
            <Box sx={{mb: 20}}>
                <Button onClick={() => setDisplayEditor(false)}>
                    <Icon name='arrow alternate circle left'/>
                    Go back
                </Button>
            </Box>
            {showInputs && orderType == "Custom"? (
                <Box>
                    <Box
                        sx={{
                            width: 400,
                            margin: '0 auto',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Dropdown
                            placeholder='Select custom boot'
                            fluid
                            selection
                            // options={devCustomBoots}
                            options={prodCustomBoots}
                            onChange={(_, {value}) => setBootSelection(value)}
                        />
                    </Box>
                    <Box sx={{mt: 20, float: 'right', mb: 30}}>
                        <Button as='button' color='green' onClick={() => getModelSpecsCustom()}>
                            Search for model <Icon name='arrow circle right'/>
                        </Button>
                    </Box>
                </Box>
            ) : showInputs && orderType == "Stock" ? (
                <Box>
                    <Box
                        sx={{
                            width: 400,
                            margin: '0 auto',
                            justifyContent: 'space-between',
                        }}
                    >
                        <BootModelsDropdown
                            bootModel={bootModel}
                            setBootModel={setBootModel}
                        />
                        <Box sx={{mt: 10}}>
                            <ColorDropdown color={color} setColor={setColor}/>
                        </Box>
                    </Box>
                    <Box sx={{mt: 20, float: 'right', mb: 30}}>
                        <Button color='green' as='button' onClick={() => findModelFromDBStock()}>
                            Search for model <Icon name='arrow circle right'/>
                        </Button>
                    </Box>
                </Box>
            ) : showInputs && orderType == "Belt"? (
                <Box>
                  <Box
                    sx={{
                      width: 400,
                      margin: '0 auto',
                      justifyContent: 'space-between',
                    }}
                  >
                    <BeltModelsDropdown
                      belt={belt}
                      setBelt={setBelt}
                    />
                  </Box>
                  <Box sx={{ mt: 20, float: 'right', mb: 30 }}>
                    <Button color='green' as='button' onClick={() => findModelFromDBBelt()}>
                      Search for model <Icon name='arrow circle right' />
                    </Button>
                  </Box>
                </Box>
              ) : productSpecsNotFound ? (
                <Segment>The product specifications have not been found!</Segment>
            ) : specFormOpen ? (
                <Segment basic textAlign='center'>
                    <Ticket
                        infoForForm={infoForForm}
                        orderType={orderType}
                        lineItemPosition={0}
                        setSpecFormOpen={setSpecFormOpen}
                        setShowInputs={setShowInputs}
                        reprintBy={reprintBy}
                        credential={credential}
                    />
                </Segment>
            ) :null}
        </Segment>
    );
};