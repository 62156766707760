import { React, useEffect, useState } from 'react';
import { Form, Header } from 'semantic-ui-react';

export const SpecialtyItems = ({
  specialtyBoxString,
  setSpecialtyBoxString,
}) => {
  const [leftSizeHeelCheckbox, setLeftSizeHeelCheckbox] = useState(false);
  const [rightSizeHeelCheckbox, setRightSizeHeelCheckbox] = useState(false);
  const [rightLittleToeCheckbox, setRightLittleToeCheckbox] = useState(false);
  const [leftLittleToeCheckbox, setLeftLittleToeCheckbox] = useState(false);
  const [rightInnerBunionCheckbox, setRightInnerBunionCheckbox] =
    useState(false);
  const [rightOuterBunionCheckbox, setRightOuterBunionCheckbox] =
    useState(false);
  const [leftInnerBunionCheckbox, setLeftInnerBunionCheckbox] = useState(false);
  const [leftOuterBunionCheckbox, setLeftOuterBunionCheckbox] = useState(false);
  const [rightMetatarsalPlus1Checkbox, setRightMetatarsalPlus1Checkbox] =
    useState(false);
  const [leftMetatarsalPlus2Checkbox, setLeftMetatarsalPlus2Checkbox] =
    useState(false);
  const [leftHeelPinCheckbox, setLeftHeelPinCheckbox] = useState(false);
  const [rightHeelPinCheckbox, setRightHeelPinCheckbox] = useState(false);
  const [leftInstepPieceCheckbox, setLeftInstepPieceCheckbox] = useState(false);
  const [rightInstepPieceCheckbox, setRightInstepPieceCheckbox] = useState(false);

  useEffect(() => {
    var text = ""
    if (leftSizeHeelCheckbox == true) {
        text = text + "L. minus 1 size heel || "
    }
    if (rightSizeHeelCheckbox == true) {
        text = text + "R. minus 1 size heel || "
    }
    if (rightLittleToeCheckbox == true) {
        text = text + "R. little toe pocket || "
    }
    if (leftLittleToeCheckbox == true) {
        text = text + "L. little toe pocket || "
    }
    if (rightInnerBunionCheckbox == true) {
        text = text + "R. inner bunion piece || "
    }
    if (rightOuterBunionCheckbox == true) {
        text = text + "R. outer bunion piece || "
    }
    if (leftInnerBunionCheckbox == true) {
        text = text + "L. inner bunion piece || "
    }
    if (leftOuterBunionCheckbox == true) {
        text = text + "L. outer bunion piece || "
    }
    if (rightMetatarsalPlus1Checkbox == true) {
        text = text + "R. metatarsal piece +1 size || "
    }
    if (leftMetatarsalPlus2Checkbox == true) {
        text = text + "L. metatarsal piece +2 size || "
    }
    if (leftHeelPinCheckbox == true) {
        text = text + "L. heel pin || "
    }
    if (rightHeelPinCheckbox == true) {
        text = text + "R. heel pin || "
    }
    if (leftInstepPieceCheckbox == true) {
        text = text + "L. instep piece || "
    }
    if (rightInstepPieceCheckbox == true) {
        text = text + "R. instep piece || "
    }
    text = text.slice(0, -3)
    setSpecialtyBoxString(text)
  }, [
    leftSizeHeelCheckbox,
    rightSizeHeelCheckbox,
    rightLittleToeCheckbox,
    leftLittleToeCheckbox,
    rightInnerBunionCheckbox,
    rightOuterBunionCheckbox,
    leftInnerBunionCheckbox,
    leftOuterBunionCheckbox,
    rightMetatarsalPlus1Checkbox,
    leftMetatarsalPlus2Checkbox,
    leftHeelPinCheckbox,
    rightHeelPinCheckbox,
    leftInstepPieceCheckbox,
    rightInstepPieceCheckbox,
  ]);
  return (
    <Form.Field>
      <Header>Specialty Foot Items</Header>
      <Form.Group grouped>
        <Form.Field
          label='Left minus 1 size heel'
          control='input'
          type='checkbox'
          onChange={() => setLeftSizeHeelCheckbox(!leftSizeHeelCheckbox)}
        />
        <Form.Field
          label='Right minus 1 size heel'
          control='input'
          type='checkbox'
          onChange={() => setRightSizeHeelCheckbox(!rightSizeHeelCheckbox)}
        />
        <Form.Field
          label='Right little toe pocket'
          control='input'
          type='checkbox'
          onChange={() => setRightLittleToeCheckbox(!rightLittleToeCheckbox)}
        />
        <Form.Field
          label='Left little toe pocket'
          control='input'
          type='checkbox'
          onChange={() => setLeftLittleToeCheckbox(!leftLittleToeCheckbox)}
        />
        <Form.Field
          label='Right inner bunion piece'
          control='input'
          type='checkbox'
          onChange={() =>
            setRightInnerBunionCheckbox(!rightInnerBunionCheckbox)
          }
        />
        <Form.Field
          label='Right outer bunion piece'
          control='input'
          type='checkbox'
          onChange={() =>
            setRightOuterBunionCheckbox(!rightOuterBunionCheckbox)
          }
        />
        <Form.Field
          label='Left inner bunion piece'
          control='input'
          type='checkbox'
          onChange={() => setLeftInnerBunionCheckbox(!leftInnerBunionCheckbox)}
        />
        <Form.Field
          label='Left outer bunion piece'
          control='input'
          type='checkbox'
          onChange={() => setLeftOuterBunionCheckbox(!leftOuterBunionCheckbox)}
        />
        <Form.Field
          label='Right metatarsal piece +1 size'
          control='input'
          type='checkbox'
          onChange={() =>
            setRightMetatarsalPlus1Checkbox(!rightMetatarsalPlus1Checkbox)
          }
        />
        <Form.Field
          label='Left metatarsal piece +2 size'
          control='input'
          type='checkbox'
          onChange={() =>
            setLeftMetatarsalPlus2Checkbox(!leftMetatarsalPlus2Checkbox)
          }
        />
        <Form.Field
          label='Left heel pin'
          control='input'
          type='checkbox'
          onChange={() => setLeftHeelPinCheckbox(!leftHeelPinCheckbox)}
        />
        <Form.Field
          label='Right heel pin'
          control='input'
          type='checkbox'
          onChange={() => setRightHeelPinCheckbox(!rightHeelPinCheckbox)}
        />
        <Form.Field
          label='Left instep piece'
          control='input'
          type='checkbox'
          onChange={() => setLeftInstepPieceCheckbox(!leftInstepPieceCheckbox)}
        />
        <Form.Field
          label='Right instep piece'
          control='input'
          type='checkbox'
          onChange={() => setRightInstepPieceCheckbox(!rightInstepPieceCheckbox)}
        />
      </Form.Group>
    </Form.Field>
  );
};
